<section class="workspaces-banner">
    <div class="ws-img">
        <img src="./images/download-dms-img.svg" alt="" class="img-fluid">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
                <div class="row d-flex align-items-center justify-content-between">

                    <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12">
                        <div class="thankyou-content-500 ">
                            <h1 class="heading-2"><span class="underline-highlight">Thank you</span> for downloading
                                dox2U’s Tally Plugin.</h1>
                            <p class="common-para-info ">Once downloaded, here are the steps you can use to set up your Tally Plugin</p>

                            <div class="d-flex justify-content-start align-items-start step-margin steps-container">
                                <a (click)="scrollToStep1()" class="" target="_self">
                                    <p class="common-para-step labelSet active" style="width: 55px;
                                    margin-top: 4px;"> Step 1&nbsp;</p>
                                </a>


                                <span class="stepInfo d-flex justify-content-between"> <span style="margin-right: 4px;">-</span> Install the Plugin </span>
                            </div>
                            <div class="d-flex justify-content-start align-items-start steps-container step-margin">
                                <a (click)="scrollToStep2()" class="" target="_self">
                                    <p class="common-para-step labelSet step" style="width: 55px;
                                    margin-top: 4px;"> Step 2&nbsp; </p>
                                </a>


                                <span class="ownerInfo stepInfo d-flex justify-content-between"> <span style="margin-right: 4px;">-</span> Enable the Tally Plugin</span>
                            </div>
                            <div class="d-flex justify-content-start align-items-start step-margin steps-container">
                                <a (click)="scrollToStep3()" class="" target="_self">
                                    <p class="common-para-step labelSet step" style="width: 55px;
                                    margin-top: 5px;"> Step 3&nbsp; </p>
                                </a>

                                <span class="ownerInfo stepInfo d-flex justify-content-between"> <span style="margin-right: 4px;">-</span> Create your dox2U Workspace</span>
                            </div>
                            <div class="d-flex justify-content-start align-items-start step-margin steps-container last-para">
                                <a (click)="scrollToStep4()" class="" target="_self">
                                    <p class="common-para-step labelSet step" style="width: 55px;
                                    margin-top: 3px;"> Step 4&nbsp; </p>
                                </a>

                                <span class="ownerInfo stepInfo d-flex justify-content-between"> <span style="margin-right: 2px;">-</span> Enter your Workspace Credentials  </span>
                            </div>
                            <div class="d-flex justify-content-start align-items-center step-margin steps-container">
                                <a href="#check3" class="" target="_self">
                                    <p class="common-para-step labelSet step" style="visibility: hidden;"> Step 4 -&nbsp; </p>
                                </a>

                                <span class="ownerInfo stepInfo stepInfo4"> to finish setting up your Tally Plugin</span>
                            </div>

                            <div class="d-flex flex-column step-moreInfo">
                                <span class="mb-4">In case the download does not
                                    start automatically, please <a (click)="downloadPdf()" class="thankyouhyper"> click here
                                    </a></span>
                                    
                                <a class="thankyouhyper"
                                    href="https://dox2uprod.blob.core.windows.net/websitedocs/dox2U%20Tally%20Add-On%20Installation%20Instructions.pdf?sp=r&st=2024-04-22T05:54:20Z&se=2040-04-22T13:54:20Z&sv=2022-11-02&sr=b&sig=x8UUAnAi8cbBXxotiVdzfpauLG7KbZfwTcJYYHOlBJw%3D"
                                    download>Download Installation Instructions</a>
                            </div>

                        </div>
                    </div>
                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 align-items-center justify-content-end d-flex p-0">                   
                        <div class="card shadow-sm paddingForm wspace-bg dox2u-ws">
                            <div id="tallyDMSshow" *ngIf="wsCreated">
                                <h3 class=" heading-wspace"><span class="underline-highlight">Create</span> your dox2U
                                    Workspace</h3>
                                <p class="wspace-para ">You need a dox2U Workspace to use the Tally Plugin. Your 15-day trial will be created in the Business Plus plan.
                                </p>
                                <div class="para-content"   *ngIf="currentPage3 || currentPage1 || currentPage4">
                                    
                                    <div *ngIf="editBtnClicked">
                                        
                                        <div class="d-flex justify-content-start align-items-start ">

                                            <p class="common-para labelSet">Owner's Full Name:</p>

                                            <span id="ownername" class="ownerInfo"  [textContent]="ownerNameDisplay"></span>
                                            
                                        </div>
                                        
                                        <div class="d-flex justify-content-start align-items-start ">

                                            <p class="common-para labelSet" > Owner’s Email Address:</p>

                                            <span id="owneremail" class="ownerInfo" [textContent]="ownerEmailDisplay"></span>
                                        </div>
                                        <div class="para-content para-set" 
                                        [ngClass]="[currentPage1 ? 'd-none' : '']">

                                            <p class="common-para labelSet" > Workspace Name:</p>

                                            <span class="ownerInfo margin-added" [textContent]="wsNameDisplay"> </span>
                                        </div>

                                        <div class="para-content para-set" 
                                        [ngClass]="[currentPage1 || currentPage2 || currentPage3 ? 'd-none' : '']" *ngIf="currentPage4">

                                            <p class="common-para labelSet" > Workspace URL:</p>

                                            <span class="ownerInfo margin-added" [textContent]="wsURLDisplay"> </span>
                                        </div>

                                    </div>
                                    <div [ngClass]="[!editBtnClicked || currentPage3 || currentPage4 ? 'd-none' : '']"><a type="button"id="editButton" (click)="showDiv()" class="edit-button">Edit</a>
                                    </div>
                                
                                </div>
                                <section *ngIf="currentPage1">
                                <form id="workspaceform" [formGroup]="nameWsForm"  class="WSForm">
                                    <div></div>
                                    <div class="flex-column colmG1" id="inputContainer" style="display: flex" *ngIf="currentPage2">
                                        <div id="showInput" class="d-flex justify-content-between">
                                            <div class="d-flex flex-column width50percent">
                                                <div class="nameInput d-flex">
                                                    <label>
                                                        <p class="label-field mb-1 tunset">Owner's Full Name<span
                                                                style="color:red">*</span>
                                                        </p>
                                                    </label>
                                                    <input class="input-field inputwith100" formControlName="firstname" id="firstname" name="firstname" (keypress)="resetFirstNameDiv($event)" minlength="3" maxlength="99">
                                                </div>
                                                <p id="errfullname" *ngIf="firstNameValidDiv" class="mb-0  meserr">Name is required</p>
                                                <p id="errfullname" class="mb-0 meserr" *ngIf="firstNameMaxLengthDiv">Name cannot contain more than 30 characters</p>
                                                <p id="errfullname" class="mb-0 meserr" *ngIf="firstNameMinLengthDiv">Name cannot contain less than 3 characters</p>
                                            </div>
                                            <div class="d-flex flex-column width50percent">
                                                <div class="nameInput  d-flex">
                                                    <label>
                                                        <p class="label-field mb-1 tunset">Owner’s Email Address<span
                                                                style="color:red">*</span>
                                                        </p>
                                                    </label>
                                                    <input class="input-field inputwith100" formControlName="email" id="email" name="email" (keypress)="resetemailDiv($event)">                                                           
                                                </div>
                                                <p id="errfullname" *ngIf="emailEmptyDiv" class="mb-0  meserr">Email is required</p>
                                                <p id="errfullname" *ngIf="emailValidDiv" class="mb-0  meserr">Enter valid email address</p>
                                            </div>
                                        </div>
                                        <!-- <div class="d-flex flex-column colmG1">
                                            <div class="d-flex flex-column">
                                                <div class="nameInput workspaceName d-flex flex-column">
                                                    <label class="label-field">
                                                        Workspace Name<span
                                                                style="color:red">*</span>
                                                    </label>
                                                    <input class="input-field inputwith100" id="workspaceName" formControlName="wsname"
                                                        name="workspaceName" (keypress)="resetwsNameDiv($event)">
                                                </div>
                                                <p id="errworkspacename" *ngIf="wsNameValidDiv" class="mb-0 meserr">Enter workspace name in correct format</p>
                                            </div>
                                            <div>
                                            </div>
                                        </div> -->
                                        <div>
                                        </div>
                                      
                                    </div>
                                    <div class="flex-column colmG1" id="inputContainer" class="wsname-container" > 
                                       
                                        <div class="d-flex flex-column colmG1 w-100">
                                            <div class="w-100">
                                                <div class="nameInput d-flex flex-column w-100">
                                                    <label class="label-field">
                                                        Workspace Name<span
                                                                style="color:red">*</span>
                                                    </label>
                                                    <input class="input-field inputwith100 inputurl" id=""
                                                        name="workspaceName" formControlName="wsname" (keypress)="resetwsNameDiv($event)" minlength="3" 
                                                        (keypress)="removeSpecialCharactersName($event)" maxlength="30">
                                                </div>
                                                <p id="errworkspacename" class="mb-0 meserr" *ngIf="wsNameValidDiv">Enter workspace name in correct format</p>
                                                <p id="errworkspacename" class="mb-0 meserr" *ngIf="wsNameEmptyDiv">Workspace Name is required</p>
                                                <p id="errworkspacename" class="mb-0 meserr" *ngIf="wsminlengthdiv">Must contain atleast 3 characters</p>
                                                <p id="errworkspacename" class="mb-0 meserr" *ngIf="wsmaxlengthdiv">Name cannot contain more than 30 characters</p>
                                                <p id="errworkspacename" class="mb-0 meserr" *ngIf="spclCharWsNamediv">Cannot contain special characters except - ( ) . _ &</p>
                                                <p id="errworkspacename" class="mb-0 meserr" *ngIf="startWithAlphabetdiv">Workspace Name should start with an alphabet</p>
                                                <p id="errworkspacename" class="mb-0 meserr" *ngIf="wsNameNotAvailable">This WS Name is either already taken or uses words that are not allowed. Please enter a different WS Name and try again</p>
                                                

                                                

                                            </div>
                                            <div>
                                            </div>
                                        </div>
                                        <div>
                                        </div>
                                      
                                    </div>

                                    
                                    <div class="form-button" >
                                        <button type="submit" class="button-primary buttonNext1 trydox" name="dox2usubmitus"
                                            id="nextButton1" (click)="proceedToNext1()" > <span class="spinner-border" *ngIf="isUrlLoaded"></span><span *ngIf="!isUrlLoaded">Next</span> </button>
                                    </div>
                                   
                                </form>
                                </section>
<!-- 
                                <section *ngIf="currentPage2">
                                    <form id="workspaceform2" [formGroup]="form" (ngSubmit)="onSecondSubmit()" class="WSForm">
                                        <div></div>
                                        <div class="flex-column colmG1" id="inputContainer" style="display: flex">
                                            <div id="showInput" class="d-flex justify-content-between">
                                                <div class="d-flex flex-column width50percent">
                                                    <div class="nameInput d-flex">
                                                        <label>
                                                            <p class="label-field mb-1 tunset">Owner's Full Name<span
                                                                    style="color:red">*</span>
                                                            </p>
                                                        </label>
                                                        <input class="input-field inputwith100" formControlName="firstname" id="firstname" name="firstname" (keypress)="resetFirstNameDiv($event)">
                                                    </div>
                                                    <p id="errfullname" *ngIf="firstNameValidDiv" class="mb-0  meserr">Name is required</p>
                                                </div>
                                                <div class="d-flex flex-column width50percent">
                                                    <div class="nameInput  d-flex">
                                                        <label>
                                                            <p class="label-field mb-1 tunset">Owner’s Email Address<span
                                                                    style="color:red">*</span>
                                                            </p>
                                                        </label>
                                                        <input class="input-field inputwith100" formControlName="email" id="email" name="email" (keypress)="resetemailDiv($event)">                                                           
                                                    </div>
                                                    <p id="errfullname" *ngIf="emailEmptyDiv" class="mb-0  meserr">Email is required</p>
                                                    <p id="errfullname" *ngIf="emailValidDiv" class="mb-0  meserr">Enter valid email address</p>
                                                </div>
                                            </div>
                                            <div class="d-flex flex-column colmG1">
                                                <div class="d-flex flex-column">
                                                    <div class="nameInput workspaceName d-flex flex-column">
                                                        <label class="label-field">
                                                            Workspace Name<span
                                                                    style="color:red">*</span>
                                                        </label>
                                                        <input class="input-field inputwith100" id="workspaceName" formControlName="wsname"
                                                            name="workspaceName" (keypress)="resetwsNameDiv($event)">
                                                    </div>
                                                    <p id="errworkspacename" *ngIf="wsNameValidDiv" class="mb-0 meserr">Enter workspace name in correct format</p>
                                                </div>
                                                <div>
                                                </div>
                                            </div>
                                            <div>
                                            </div>
                                          
                                        </div>
                                        <div class="form-button" >
                                            <button type="submit" class="button-primary trydox" name="dox2usubmitus"
                                                id="nextButton2" (click)="proceedToNext2()">Next</button>
                                        </div>
                                       
                                    </form>
                                    </section> -->

                                
                                    <section *ngIf="currentPage3">
                                        <form id="workspaceform" [formGroup]="WsurlForm" class="WSForm wsurlForm">
                                            <div></div>
                                            <div class="flex-column colmG1" id="inputContainer" style="display: flex">
                                               
                                                <div class="d-flex flex-column colmG1">
                                                    <div class="d-flex flex-column">
                                                        <div class="nameInput d-flex flex-column">
                                                            <label class="label-field">
                                                                Workspace URL<span
                                                                        style="color:red">*</span>
                                                            </label>
                                                            <span class="ws-input-label responsive-url" [attr.data-domain]="finalDomain"></span>
                                                            <input class="input-field inputwith100 inputurl" id=""
                                                                name="workspaceurl" formControlName="wsurl" (keypress)="resetwsUrlDiv($event)" minlength="3" maxlength="25"  autocomplete="off" (keypress)="removeSpecialCharacterswsUrl($event)"  (paste)="onPasteWsaddress($event)">
                                                        </div>
                                                        
                                                        <p class="url-para">Your unique URL is used to access your dox2U Workspace</p>
                                                        <p id="errworkspaceurl" class="mb-0 meserr" *ngIf="wsUrlEmptyDiv">Workspace URL is required</p>
                                                        <p id="errworkspaceurl" class="mb-0 meserr" *ngIf="domainAlreadyUsed">This WS URL is either already taken or uses words that are not allowed. Please enter a different URL and try again</p>
                                                        <p id="errworkspaceurl" class="mb-0 meserr" *ngIf="wsUrlMinLengthDiv">Workspace URL must contain atleast 3 characters</p>
                                                        <p id="errworkspaceurl" class="mb-0 meserr" *ngIf="wsUrlMaxLengthDiv">Workspace URL cannot contain more than 25 characters</p>
                                                        <p id="errworkspaceurl" class="mb-0 meserr" *ngIf="wsUrlAlphabetDiv">Must contain at least one alphabet</p>
                                                        
                                                        <p class="url-para">Some suggested URLs</p>

                                                        <!-- logic for checking if ws URL is already used -->

                                                        <div [ngClass]="{}" class="suggestedws-url">

                                                            <div class="d-block align-items-left justify-content-left flex-column suggested-url-1"
                                                              *ngFor="let a of suggestion">
                                      
                                                              <p class="text-left text-primary" role="button" (click)="subDomainSet(a?.subDomain)">
                                                                {{a?.subDomain}}</p>
                                                            </div>
                                      
                                                          </div>
        
                                                    </div>
                                                    <div>
                                                    </div>
                                                </div>
                                                <div>
                                                </div>
                                              
                                            </div>
                                            <div class="form-button nextButton3-container" >
                                                <a type="button" class="back-button" (click)="backToPage1()"><img src="../../../assets/Image/VectorBack.svg"> Back</a>
                                                <button type="submit" class="button-primary trydox" name="dox2usubmitus" (click)="createWS()"
                                                    id="nextButton3" >Next</button>
                                            </div>
                                           
                                        </form>
                                        </section>
                                       
                                        <section *ngIf="currentPage4">
                                            <form id="workspaceform" [formGroup]="passwordForm" (ngSubmit)="onSubmit()" class="WSForm" >
                                                <div></div>
                                                <div class="flex-column colmG1" id="inputContainer" style="display: flex">
                                                   
                                                    <div class="d-flex flex-column colmG1">
                                                        <div class="d-flex flex-column">
                                                            <div class="nameInput d-flex flex-column">
                                                                <label class="label-field">
                                                                    Password<span
                                                                            style="color:red">*</span>
                                                                </label>
                                                                
                                                                <input class="input-field inputwith100 inputurl" [type]="getInputType()" oncopy="return false;"
                                                                onpaste="return false;" ondrop="return false;" autocomplete="off" (keyup.enter)="onEnterPassword($event);" (keyup)="checkPasswordStrength($event)"
                                                                    name="workspacepswd" formControlName="password" (keypress)="resetwspswdDiv($event)"
                                                                    [ngClass]="{'borderInput' : !passwordLength  || !containLowerCase || !containSpecialCharacter || !containNumber || !containUpperCase, 'changeInput' : (!passwordLength && submitted) || (!containLowerCase && submitted) || (!containSpecialCharacter && submitted) || (!containNumber && submitted) || (!containUpperCase && submitted)}">
                                                                 
                                                                            <img src="../../../assets/Image/eye.svg" class="eye-img" [ngClass]="{'checkmarkcolor' : showPassword}" *ngIf="showPassword ? 'eye-outline' : 'eye-off-outline'" (click)="toggleShowPassword()">
                                                                        
                                                                
                                                            </div> 
                                                            <p id="errworkspaceurl" class="mb-0 meserr" *ngIf="wspswdValidDiv">Incomplete password</p> 
                                                            <p id="errworkspaceurl" class="mb-0 meserr" *ngIf="wspswdEmptyDiv">Cannot be blank</p>                                                                
                                                        </div>
                                                        <div class="text-danger my-1" *ngIf="passwordForm.get('password')?.hasError('maxlength')">
                                                            Password cannot be more than 100 Characters.</div>
                                                            <div class="sign-in-para d-flex flex-row justify-content-left pt-2">
                                                            <ul class="text-basic password-validation-list-1 text-secondary">
                                                                <li class="mb-2 d-flex align-items-center pass-validation-list pt-2"
                                                                    [ngClass]="{'blue' : passwordLength, 'normal' : !passwordLength, 'red' : (!passwordLength && submitted), 'errorpassword' : (!passwordLength && submitted)}">
                                                                    At least 6 characters</li>
                                                                <li class="mb-2 d-flex align-items-center pass-validation-list pt-2"
                                                                    [ngClass]="{'blue' : containLowerCase, 'normal' : !containLowerCase, 'red' : (!containLowerCase && submitted), 'errorpassword' : (!containLowerCase && submitted)}">
                                                                    One lower case a-z</li>
                                                                <li class="mb-2 d-flex align-items-center pass-validation-list pt-2"
                                                                    [ngClass]="{'blue' : containSpecialCharacter, 'normal' : !containSpecialCharacter, 'red' : (!containSpecialCharacter && submitted), 'errorpassword' : (!containSpecialCharacter && submitted)}">
                                                                    One special character</li>
                                                            </ul>
                                                            <ul class="password-validation-list-1 text-basic text-secondary">
                                                                <li class="mb-2 d-flex align-items-center pass-validation-list pt-2"
                                                                    [ngClass]="{'blue' : containNumber, 'normal' : !containNumber, 'red' : (!containNumber && submitted), 'errorpassword' : (!containNumber && submitted)}">
                                                                    One number 0-9</li>
                                                                <li class="mb-2 d-flex align-items-center pass-validation-list pt-2" id="list-2"
                                                                    [ngClass]="{'blue' : containUpperCase, 'normal' : !containUpperCase,'red' : (!containUpperCase && submitted), 'errorpassword' : (!containUpperCase && submitted)}">
                                                                    One upper case A-Z</li>
                                                                <li class="mb-2 d-flex align-items-center pass-validation-list pt-2" id="list-2"
                                                                    [ngClass]="{'blue' : containUpperCase, 'normal' : !containUpperCase,'red' : (!containUpperCase && submitted), 'errorpassword' : (!containUpperCase && submitted)}">
                                                                    Can’t use previous password</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div>
                                                    </div>
                                                  
                                                </div>
                                                <div class="form-button nextButton3-container" >
                                                    <a type="button" class="back-button" (click)="backToPage3()"><img src="../../../assets/Image/VectorBack.svg"> Back</a>
                                                    <button type="button" class="button-primary trydox" id="nextButton4" (click)="onEnterPassword($event);wsCreateProfile()"
                                                    >Create</button>
                                                </div>
                                               
                                            </form>
                                            
                                            
                                                
                                            </section>
                                            
                                   
                        </div>
                        <section *ngIf="ThankyouPageShow" class="thankyou-container">
                            <div id="downloaddmshide"  class="d-flex  flex-column justify-content-center align-items-center" >
                                <img src="../../../assets/Image/createWSSuccess.svg">
                                <p class="common-para text-center success-heading">Congratulations!</p>
                                <p class="common-para text-center success-content">Your workspace has been successfully created</p>
                               
                            </div>
                        </section>
                    </div> 
                </div>
            </div>
        </div>
        </div>
    </div>
    
</section>

<section class="top-padd physical-cabinets partner-section download-bg common-padd ">
    <div class="container">
        <div class="row">
                    <div class="more-features-top-content">
                        <h6 class="heading-6">A SIMPLE INTEGRATED SOLUTION</h6>
                        <h2 class="heading-2 fontwhy32">Getting started with dox2U’s Tally Plugin
                            takes just a few clicks</h2>
                        
                    </div>
                </div>
            </div>
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto reseller-image-container p-0">
            <app-right-image-left-text id="step1" [imgUrl]="imagePathDMSAddon1" [content]="imgContentDMSAddon1"></app-right-image-left-text>
            <app-left-image-right-text id="step2" class="downloadPage-section" [imgUrl]="imagePathDMSAddon2" [content]="imgContentDMSAddon2"></app-left-image-right-text>
            <app-right-image-left-text id="step3" [imgUrl]="imagePathDMSAddon3" [content]="imgContentDMSAddon3"></app-right-image-left-text>
            <app-left-image-right-text id="step4" class="downloadPage-section" [imgUrl]="imagePathDMSAddon4" [content]="imgContentDMSAddon4"></app-left-image-right-text>
            <app-right-image-left-text id="step5" [imgUrl]="imagePathDMSAddon5" [content]="imgContentDMSAddon5"></app-right-image-left-text>
            <!-- <app-left-image-right-text id="step6" class="downloadPage-section" [imgUrl]="imagePathDMSAddon6" [content]="imgContentDMSAddon6"></app-left-image-right-text> -->

        </div>

</section>

<section class="schedule-call">
    <div class="container-fluid py-5">
        <div class="col-12 d-flex justify-content-center align-items-center text-center">
        <div class="row">
            <p class="common-para mb-0" id="check1">In case you face any issues getting dox2U’s Tally DMS Plugin set up or have any feedback for us, please do not hesitate to reach out to <a href="mailto:support@dox2u.com" target="_blank" class="thankyouhyper">support@dox2u.com</a></p>
        </div>
        </div>
    </div>
</section>
<app-getting-started></app-getting-started>
<div class="gradient-divider"></div>
