import { Component, OnInit, ViewChild } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';
@Component({
  selector: 'app-tally-management-plugin',
  templateUrl: './tally-management-plugin.component.html',
  styleUrls: ['./tally-management-plugin.component.scss']
})
export class TallyManagementPluginComponent implements OnInit{
  isCountdownFinished = false;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    center: true,
   // navSpeed: 600,
    //autoplay: false,
    //autoplayTimeout: 1000,
    autoplay: true,
    responsive: {
      0: {
        items: 1 
      },
      400: {
        items: 1
      },
      560: {
        items: 3
      },
      1000: {
        items: 3
      }
    },
  }

  customOptionssec: OwlOptions = {
    items: 5,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    //center: true,
   // navSpeed: 600,
    //autoplay: false,
    //autoplayTimeout: 1000,
    autoplay: true,
    responsive: {
      0: {
        items: 1 
      },
      400: {
        items: 2,
        slideBy: 1
      },
      560: {
        items: 2,
        slideBy: 1
      },
      1000: {
        items: 3
      }
    },
  }
  constructor(private router: Router,) {}
  ngOnInit() {
    
  }
  showSecureCloudData: boolean = true;
    showRobustDocsData: boolean = false;
  
  callSecureData() {
    this.showRobustDocsData = false;
    this.showSecureCloudData = true;
  }

  callRobustData() {
    this.showSecureCloudData = false;
    this.showRobustDocsData = true;
  }
  onCountdownEnd(event: boolean): void {
    if (event) {
      this.isCountdownFinished = true;
      // if(window.location.href.endsWith('tally')) {
      //   this.router.navigate(['/tallydmsplugin'])
      // }
      
    }
  }

}
