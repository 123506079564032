
    <div class="container productLeft">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
          <div class="row d-flex align-items-center">
  
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 p-0 plAlign">
              <div class="workspace-banner-content document-handling-banner-content">
                <h6 class="heading-6 text-uppercase">{{contentH.heading}}</h6>
                <h1 class="heading-1">{{contentH.subTitle}}&nbsp;<span class="underline-highlight">{{contentH.HighlightedText}}</span></h1>
                
                <p class="common-para">{{contentH.description}}</p>
                <p class="italicText">{{contentH.italicText}}</p>
                <div class="d-flex">
                <a *ngIf="contentH.buttonText" (click)="scrollToGettingStarted($event)" target="_blank"> <button class="button-primary">{{contentH.buttonText}}</button></a>
                <a *ngIf="contentH.buttonText2" (click)="openCalendlyPage()" target="_blank" class="underlineNone"> <button class="button-transparent btn-schedule d-flex align-items-center">{{contentH.buttonText2}}</button></a>
              </div>
              </div>
            </div>
  
            <!-- <div class="col-xxl-1 d-xl-none d-xxl-block d-lg-none d-md-none d-sm-none"></div> -->
  
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 justify-content-center">
  
              <img src={{imgSrc}} alt="document handling system" class="img-fluid img-size drop-shadow aos-init aos-animate" data-aos="fade-left" data-aos-duration="2500">
             
            </div>
          </div>
        </div>
      </div>
    </div>
