<!-- <section class="new-feature-section" id="newFeature" *ngIf="!isLaunchTime">
    <div class="container-fluid">
        <div class="row">
            <div
                class="new-feature-container col-xxl-10 col-xl-10 col-lg-12 col-md-12 col-sm-12 mx-auto ">
                <div class="blog-top-bar d-flex justify-content-center flex-column align-items-center ">
                    <h6 class="heading-6">NEW FEATURES</h6>
                    <h3 class="sub-heading-feature text-center">Some exciting new capabilities added to dox2U</h3>
                </div>
                <div class="d-flex flex-column flex-wrap">
                    <div class="row exciting-bg-1 align-items-center mx-0">
                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mx-auto pr-0">
                            <div class="d-flex flex-column center-align-div">
                                <p class="exciting-para-color heading-6 mb-1">Dockets re-defined</p>
                                <h2 class="heading-3 exciting-margin-bottom center-align-div" >Improved Dockets with added&nbsp; <br><span class="underline-highlight-ex">capabilities for</span> </h2>
                                <ul class="p-0 mb-0" style="margin-top: 30px;">
                                    <li class="d-flex margin-10 justify-content-start align-items-center "><img src="../../../assets/Image/excheck.svg" alt=""
                                            class="img-fluid mr-1 img-fluid-mr">
                                        <p class="common-para exciting-para-color mb-0 access-align ">Creating custom Docket templates
                                        </p>
                                    </li>
                                  
                                    <li class="d-flex margin-10 justify-content-start align-items-center "><img src="../../../assets/Image/excheck.svg" alt=""
                                            class="img-fluid mr-1 img-fluid-mr">
                                        <p class="common-para exciting-para-color mb-0 access-align ">Dockets dashboard for quick access
                                        </p>
                                    </li>
                                    <li class="d-flex margin-10 justify-content-start align-items-center  "><img src="../../../assets/Image/excheck.svg" alt=""
                                            class="img-fluid mr-1 img-fluid-mr">
                                        <p class="common-para exciting-para-color mb-0 access-align text-left">Flexible organizing of information
                                        </p>
                                    </li>
  
                                </ul>
                                <p class="mt-3 pt-1 pb-4 common-para"><a (click)="navigateToDockets()" class="text-white tally-link cursor-pointer">Learn more <img src="../../../assets/Image/learnmore.svg" ></a></p>
                            </div>
                        </div>
                        <div class=" col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mx-auto p-0 d-flex align-items-center justify-content-center">
                            <div class="d-flex flex-column center-align-div">
                                <img src="../../../assets/Image/improved_dockets.svg" class="img-fluid">
  
                            </div>
                        </div>
  
                    </div>
                    <div class="d-flex align-items-center new-feat-column">
                        <div class="bg-docket p-0  bg-docket p-0 width31">
                            <div class="docket-card overflow-hidden card-height">
                                <div class="padding-top-section card-1">
                                    <h4 class="docket-text mb-2 pb-1">Share with guests</h4>
                                   <div class="docket-para mb-2 pb-1">
                                   <p class="common-para mb-0">Secured & controlled sharing with external guests & stakeholders with enhanced security, OTP and controls.</p>
                                   </div>
                                    <p class="common-para mb-0 pb-1 card-1-learn"> <a href="guest-access" class="text-white"> Learn more<img src="../../../assets/Image/learnmore.svg" style="padding-left:3px " ></a></p>
                                </div>
                                <div class="d-flex justify-content-end img-container">
                                    <img src="../../../assets/Image/share-with-guest.svg" class="img-fluid dplay">
                                    <img src="../../../assets/Image/share-with-guestResp.svg" class="img-fluid dplayResp">
                                    
  
                                </div>
                            </div>
                        </div>
                        <div class="bg-docket docket-integration p-0 bg-docket p-0 width31">
                            <div class="docket-card overflow-hidden">
                                <div class="padding-top-section card-2">
                                    <h4 class="docket-text mb-2 pb-1 integration-font text-white">WhatsApp Integration</h4>
                                 <div class="docket-para mb-2 pb-1">
                                 <p class="common-para mb-0">Conveniently & securely share documents over WhatsApp using our integration with WhatsApp Business API
                                    </p>
                                 </div>
                                    <p class="common-para mb-0 card-2-learn"><a (click)="scrollTowhatsappUpdateFeature()" style="color:white; cursor: pointer;">Learn more <img src="../../../assets/Image/learnmore.svg"></a></p>
                                </div>
                                <div class="d-flex justify-content-end img-container">
                                    <img src="../../../assets/Image/send-with-whatsapp.svg" class="img-fluid whatsapp-img dplay">
                                    <img src="../../../assets/Image/send-with-whatsappResp.svg" class="img-fluid whatsapp-img dplayResp">
                                </div>
                            </div>
                        </div>
                        <div class="bg-docket p-0 bg-docket p-0 width31">
                            <div class="docket-card overflow-hidden">
                                <div class="padding-top-section pb-0 w-100 card-3">
                                    <h4 class="docket-text mb-2 pb-1">Upgraded Tally Plugin</h4>
                                <div class="docket-para mb-2 pb-1">
                                <p class="common-para mb-0">New version of dox2U’s Tally Plugin makes managing information easier with automated Voucher sync & one-click doc linking.</p>
                                </div>
                                    <p class="common-para mb-0"><a href="/tally" style="color:white" >Learn more <img src="../../../assets/Image/learnmore.svg"></a></p>
                                </div>
                        
                                <div class="d-flex justify-content-end img-container pr-0">
                                    <img src="../../../assets/Image/dox2u_home_preview.svg" class="img-fluid dplay">
                                    <img src="../../../assets/Image/dox2u_home_previewResp.svg" class="img-fluid dplayResp">
                                   
                                </div>
                            </div>
                           
                        </div>
  
                    </div>
                    <div class="row  d-flex align-items-center justify-content-between new-feat-column">
                        <div class="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-10 d-flex justify-content-center bg-docket p-0 width31 ">
                            <div class="card-height overflow-hidden">
                                <div class="padding-top-section">
                                    <h4 class=" text-basic-color mb-2 pb-1">Dockets</h4>
                                   <div class="paraHeight mb-2 pb-1">
                                   <p class="common-para mb-0">View documents categorized by business processes such as Customer Records, Purchase, Sales Transactions, etc</p>
                                   </div>
                                    <p class="common-para mb-0 pb-1"> <a style="color:white" href="./product/dockets"> Learn more<img src="./images/learnmore.svg" style="padding-left:3px "></a></p>
                                </div>
                                <div class="d-flex justify-content-end">
                                    <img src="./images/dox2u_home_dockets.svg" class="img-fluid dplay" style="width: 181px; height: 172px;">
                                    <img src="./images/dox2u_home_dockets1.svg" class="img-fluid dplay1 w-100">
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-10 d-flex justify-content-center bg-integraion p-0 width33">
                            <div class="card-height overflow-hidden">
                                <div class="padding-top-section">
                                    <h4 class=" text-basic-color1 mb-2 pb-1 integration-font">Integrations</h4>
                                 <div class="paraHeight mb-2 pb-1">
                                 <p class="common-para mb-0">Stop printing &amp; filing documents! Use dox2U’s Rest API
                                        ecosystem to connect your existing solutions and create a single source of truth
                                    </p>
                                 </div>
                                    <p class="common-para mb-0 pt-2"><a href="./tally" style="color:white">Learn more <img src="./images/learnmore.svg"></a></p>
                                </div>
                                <div class="d-flex justify-content-end">
                                    <img src="./images/dox2u_home_integration.svg" class="img-fluid w-100">
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-4 col-lg-5 col-md-7 col-sm-10 d-flex justify-content-center bg-doc p-0 width31 ">
                            <div class="card-height overflow-hidden">
                                <div class="padding-top-section ">
                                    <h4 class=" text-basic-color mb-2 pb-1">Doc Preview</h4>
                                <div class="paraHeight mb-2 pb-1">
                                <p class="common-para mb-0">Get a quick preview of your document without having to open
                                        the document in a viewer. Saves time and really quick!</p>
                                </div>
                                    <p class="common-para mb-0 pb-1"><a href="./product/features#docpr" style="color:white">Learn more <img src="./images/learnmore.svg"></a></p>
                                </div>
                                <div class="d-flex justify-content-end">
                                    <img src="./images/dox2u_home_preview.svg" class="img-fluid dplay" style="width: 181px;height: 172px;">
                                    <img src="./images/dox2u_home_preview.svg" class="img-fluid dplay1 w-100">
                                </div>
                            </div>
                        </div>
  
                    </div>
                </div>
  
  
            </div>
        </div>
    </div>
  
  </section> -->
  

<section class="new-feature-section" id="newFeature" *ngIf="isLaunchTime">
  <div class="container-fluid">
      <div class="row">
          <div
              class="new-feature-container col-xxl-10 col-xl-10 col-lg-12 col-md-12 col-sm-12 mx-auto ">
              <div class="blog-top-bar d-flex justify-content-center flex-column align-items-center ">
                  <h6 class="heading-6">NEW FEATURES</h6>
                  <h3 class="sub-heading-feature text-center">Some exciting new capabilities added to dox2U</h3>
              </div>
              <div class="d-flex flex-column flex-wrap">
                  <div class="row exciting-bg-1 align-items-center mx-0">
                      <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mx-auto pr-0">
                          <div class="d-flex flex-column center-align-div">
                              <p class="exciting-para-color heading-6 mb-1">Automated Tally Backup</p>
                              <h2 class="heading-3 exciting-margin-bottom center-align-div" >Your Tally Data Is Only as Safe<div class="underline-highlight-ex">as Its Backup</div> </h2>
                              <ul class="p-0 mb-0" style="margin-top: 30px;">
                                  <li class="d-flex margin-10 justify-content-start align-items-center "><img src="../../../assets/Image/excheck.svg" alt=""
                                          class="img-fluid mr-1 img-fluid-mr">
                                      <p class="common-para exciting-para-color mb-0 access-align ">Schedule automatic daily, weekly, or monthly backups
                                      </p>
                                  </li>
                                
                                  <li class="d-flex margin-10 justify-content-start align-items-center "><img src="../../../assets/Image/excheck.svg" alt=""
                                          class="img-fluid mr-1 img-fluid-mr">
                                      <p class="common-para exciting-para-color mb-0 access-align ">Secure, encrypted backups with antivirus detection
                                      </p>
                                  </li>
                                  <li class="d-flex margin-10 justify-content-start align-items-center  "><img src="../../../assets/Image/excheck.svg" alt=""
                                          class="img-fluid mr-1 img-fluid-mr">
                                      <p class="common-para exciting-para-color mb-0 access-align text-left">Access & Restore backups in seconds
                                      </p>
                                  </li>

                              </ul>
                              <a class="common-para exciting-para-color mb-0 access-align text-left" style="margin-top: 14px;">Sign up now for exclusive discounts</a>
                              <p class="mt-3 pt-1 pb-4 common-para"><a (click)="navigateToTallybackup()" class="text-white tally-link cursor-pointer">Learn more <img src="../../../assets/Image/learnmore.svg" ></a></p>
                          </div>
                      </div>
                      <div class=" col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mx-auto p-0 d-flex align-items-center justify-content-center">
                          <div class="d-flex flex-column center-align-div">
                              <img src="../../../assets/Image/tallybackup_feature.svg" class="img-fluid">

                          </div>
                      </div>

                  </div>
                  <div class="d-flex align-items-center new-feat-column">
                    <div class="bg-docket p-0 bg-docket p-0 width31">
                        <div class="docket-card overflow-hidden">
                            <div class="padding-top-section pb-0 w-100 card-3">
                                <h4 class="docket-text mb-2 pb-1">Upgraded Tally Plugin</h4>
                            <div class="docket-para mb-2 pb-1">
                            <p class="common-para mb-0">New version of dox2U’s Tally Plugin makes managing information easier with automated Voucher sync & one-click doc linking.</p>
                            </div>
                                <p class="common-para mb-0"><a href="/tally" style="color:white" >Learn more <img src="../../../assets/Image/learnmore.svg"></a></p>
                            </div>
                    
                            <div class="d-flex justify-content-end img-container pr-0">
                                <img src="../../../assets/Image/dox2u_home_preview.svg" class="img-fluid dplay">
                                <img src="../../../assets/Image/dox2u_home_previewResp.svg" class="img-fluid dplayResp">
                                <!-- <img src="../../../assets/Image/dox2u_home_preview.svg" class="img-fluid dplay1 w-100"> -->
                            </div>
                        </div>
                       
                    </div>
                      
                      <div class="bg-docket docket-integration p-0 bg-docket p-0 width31">
                          <div class="docket-card overflow-hidden">
                              <div class="padding-top-section card-2">
                                  <h4 class="docket-text mb-2 pb-1 integration-font text-white">WhatsApp Integration</h4>
                               <div class="docket-para mb-2 pb-1">
                               <p class="common-para mb-0">Conveniently & securely share documents over WhatsApp using our integration with WhatsApp Business API
                                  </p>
                               </div>
                                  <p class="common-para mb-0 card-2-learn"><a (click)="scrollTowhatsappUpdateFeature()" style="color:white; cursor: pointer;">Learn more <img src="../../../assets/Image/learnmore.svg"></a></p>
                              </div>
                              <div class="d-flex justify-content-end img-container">
                                  <img src="../../../assets/Image/send-with-whatsapp.svg" class="img-fluid whatsapp-img dplay">
                                  <img src="../../../assets/Image/send-with-whatsappResp.svg" class="img-fluid whatsapp-img dplayResp">
                              </div>
                          </div>
                      </div>

                      <div class="bg-docket p-0  bg-docket p-0 width31">
                        <div class="docket-card overflow-hidden card-height">
                            <div class="padding-top-section card-1">
                                <h4 class="docket-text mb-2 pb-1">Dockets</h4>
                               <div class="docket-para mb-2 pb-1">
                               <p class="common-para mb-0">Create virtual collections of documents to organize them in a manner you are used to and track them with checklists!</p>
                               </div>
                                <p class="common-para mb-0 pb-1 card-1-learn"> <a href="dockets" class="text-white"> Learn more<img src="../../../assets/Image/learnmore.svg" style="padding-left:3px " ></a></p>
                            </div>
                            <div class="d-flex justify-content-end img-container">
                                <img src="../../../assets/Image/dockets_feature.svg" class="img-fluid dplay">
                                <img src="../../../assets/Image/dockets_featureResp.svg" class="img-fluid dplayResp">
                                <!-- <img src="../../../assets/Image/dox2u_home_dockets1.svg" class="img-fluid dplay1 w-100"> -->

                            </div>
                        </div>
                    </div>
                    

                  </div>
                  <!-- <div class="row  d-flex align-items-center justify-content-between new-feat-column">
                      <div class="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-10 d-flex justify-content-center bg-docket p-0 width31 ">
                          <div class="card-height overflow-hidden">
                              <div class="padding-top-section">
                                  <h4 class=" text-basic-color mb-2 pb-1">Dockets</h4>
                                 <div class="paraHeight mb-2 pb-1">
                                 <p class="common-para mb-0">View documents categorized by business processes such as Customer Records, Purchase, Sales Transactions, etc</p>
                                 </div>
                                  <p class="common-para mb-0 pb-1"> <a style="color:white" href="./product/dockets"> Learn more<img src="./images/learnmore.svg" style="padding-left:3px "></a></p>
                              </div>
                              <div class="d-flex justify-content-end">
                                  <img src="./images/dox2u_home_dockets.svg" class="img-fluid dplay" style="width: 181px; height: 172px;">
                                  <img src="./images/dox2u_home_dockets1.svg" class="img-fluid dplay1 w-100">
                              </div>
                          </div>
                      </div>
                      <div class="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-10 d-flex justify-content-center bg-integraion p-0 width33">
                          <div class="card-height overflow-hidden">
                              <div class="padding-top-section">
                                  <h4 class=" text-basic-color1 mb-2 pb-1 integration-font">Integrations</h4>
                               <div class="paraHeight mb-2 pb-1">
                               <p class="common-para mb-0">Stop printing &amp; filing documents! Use dox2U’s Rest API
                                      ecosystem to connect your existing solutions and create a single source of truth
                                  </p>
                               </div>
                                  <p class="common-para mb-0 pt-2"><a href="./tally" style="color:white">Learn more <img src="./images/learnmore.svg"></a></p>
                              </div>
                              <div class="d-flex justify-content-end">
                                  <img src="./images/dox2u_home_integration.svg" class="img-fluid w-100">
                              </div>
                          </div>
                      </div>
                      <div class="col-xxl-4 col-xl-4 col-lg-5 col-md-7 col-sm-10 d-flex justify-content-center bg-doc p-0 width31 ">
                          <div class="card-height overflow-hidden">
                              <div class="padding-top-section ">
                                  <h4 class=" text-basic-color mb-2 pb-1">Doc Preview</h4>
                              <div class="paraHeight mb-2 pb-1">
                              <p class="common-para mb-0">Get a quick preview of your document without having to open
                                      the document in a viewer. Saves time and really quick!</p>
                              </div>
                                  <p class="common-para mb-0 pb-1"><a href="./product/features#docpr" style="color:white">Learn more <img src="./images/learnmore.svg"></a></p>
                              </div>
                              <div class="d-flex justify-content-end">
                                  <img src="./images/dox2u_home_preview.svg" class="img-fluid dplay" style="width: 181px;height: 172px;">
                                  <img src="./images/dox2u_home_preview.svg" class="img-fluid dplay1 w-100">
                              </div>
                          </div>
                      </div>

                  </div> -->
              </div>


          </div>
      </div>
  </div>

</section>

<app-countdown-timer (countdownEnded)="onCountdownEnd($event)" class="d-none"></app-countdown-timer>
