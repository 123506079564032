
<section class="main-pricing-bg-color" data-aos="fade-right" data-aos-duration="2500" loading="lazy">
<section class="pricing-new">
    <div class="container">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
                <div class="row">
                    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
                        <div class="pricing-new-banner pt-0 pb-0">
                            <div class="pricing-new-banner-content pt-3 pta100">
                                <div class="pricing-banner-content mb-4 pb-3">
                                    <h1 class="heading-1 text-center pb-1 mb-2">Pricing to suit all sizes of
                                        <span class="color-blue">business</span>
                                    </h1>
                                    <p class="common-para text-center mb-0">From individuals to big enterprises,
                                        dox2U scales for anyone and everyone</p>
                                </div>
                                <p id="country" value="country" class="d-none"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="pricing-card-sec">
    <div class="container containerOverwrite p-0">
        <div class="d-flex justify-content-center  tab-top1 align-items-center  position-relative">
            <div class="wrap">
                <label for="selector" class="mb-0 fw-700">Renews:</label>
                  <button class="button-transparent dropdown-toggle btn-dropdown" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ selectedItemResp }}</button>
            <div class="dropdown-menu"> <a *ngFor="let item of dropdownItems; let i = index" class="dropdown-item" (click)="selectItem(item, i)"> {{ item }}</a> 
            </div>

            <ul class="nav nav-tabs timeperiod-main" id="myTabs" role="tablist">
                <li class="nav-item timeperiod-listItems" *ngFor="let item of dropdownItems; let i = index" >
                  <a class="nav-link timeperiod-tab"  (click)="selectItem(item, i)" [class.active]="i === selectedItem" id="tab-{{ i }}" data-toggle="tab"  role="tab" aria-selected="true">
                    {{ item }}
                  </a>
                </li>
              </ul>
            </div>
            
        </div>
    </div>
</section>

<section class="pricing-card-sec">
    <div class="container containerOverwrite p-0">
        <!-- pricing-card -->
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
                <div class="row d-flex justify-content-center">
                <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12">
                  <div class="pricing-card">
                   
                    <div class="pricinig-card-main-content">
                        <div class="card-content">
                            <h4 class="h4 mb-2 font800px">{{dataCardBS.planName}}</h4>
                            <p class="cardParaIntro height5">{{dataCardBS.planIntro}}</p>
                            <div class="card-currency-content">
                                <div class="plan-data-highlight"><span class="highlighted-text">Plan charges:</span></div>
                                <div id="adc6" *ngIf="showAnnualData"> <label id="Annually" name="Annually"> <span class="india_price fontsizeprice" *ngIf="showIndiaPrice" > {{dataCardBS.annualPMPriceINR}} <span class="per-month">/ month</span> </span> <span class="dollar_price fontsizeprice" *ngIf="showDollarPrice" > {{dataCardBS.annualPMPriceUS}} <span class="per-month">/ month</span> </span> </label>
                                </div>
                                <div id="pc6" *ngIf="showTwoYearsData"> <label id="2ndYear" name="2ndYear"> <span *ngIf="showIndiaPrice" class="india_price fontsizeprice" > <del class="delText"> {{dataCardBS.annualPMPriceINR}}</del> {{dataCardBS.twoYearPMPriceINR}} <span class="per-month">/ month</span> </span> <span *ngIf="showDollarPrice" class="dollar_price fontsizeprice" > <del class="delText "> {{dataCardBS.annualPMPriceUS}}</del> {{dataCardBS.twoYearPMPriceUS}} <span class="per-month">/ month</span> </span> </label> </div>
                                <div id="ps6" *ngIf="showThreeYearsData"> <label id="3rdYear" name="3rdYear"> <span *ngIf="showIndiaPrice" class="india_price fontsizeprice"> <del class="delText"> {{dataCardBS.annualPMPriceINR}}</del> {{dataCardBS.threeYearPMPriceINR}} <span class="per-month">/ month</span> </span> <span *ngIf="showDollarPrice" class="dollar_price fontsizeprice" > <del class="delText"> {{dataCardBS.annualPMPriceUS}}</del> {{dataCardBS.threeYearPMPriceUS}} <span class="per-month">/ month</span> </span> </label> </div>
                                <div class="minH minH1">
                                    <div class=" mb-3 ">
                                        <div class="d-flex align-items-center  mb-1 position-relative ">
                                            <p class="cardParaIntro mb-0">billed </p>
                                            <div id="annualLabelBusiness_starter " class=" marginLeft26 tooltipMargin  tool-tip ml-1 cursor-pointer cont "  *ngIf="showAnnualData"> <span class="border-dotted cardParaIntro">annually</span>
                                                <p class="tooltiptext  table-tooltip text-center india_price" *ngIf="showIndiaPrice"> Annual price {{dataCardBS.anuualPriceINRTooltip}}</p>
                                                <p class="tooltiptext table-tooltip text-center dollar_price" *ngIf="showDollarPrice"> Annual price {{dataCardBS.anuualPriceUSTooltip}} </p>
                                            </div>
                                            <div id="2yearsLabelBusiness_starter" class="tool-tip ml-1 tooltipMargin cursor-pointer cont marginLeft26 "  *ngIf="showTwoYearsData"> <span class="border-dotted cardParaIntro">every 2 years</span>
                                                <p class="tooltiptext  table-tooltip text-center india_price" *ngIf="showIndiaPrice"> 2 years price {{dataCardBS.twoyearsPriceINRTooltip}}</p>
                                                <p class="tooltiptext table-tooltip text-center dollar_price" *ngIf="showDollarPrice"> 2 years price {{dataCardBS.twoyearsPriceUSTooltip}} </p>
                                            </div>
                                            <div id="3yearsLabelBusiness_starter" class="tool-tip ml-1 tooltipMargin cursor-pointer cont  marginLeft26"  *ngIf="showThreeYearsData"> <span class="border-dotted cardParaIntro"> every 3 years</span>
                                                <p class="tooltiptext  table-tooltip text-center india_price" *ngIf="showIndiaPrice"> 3 years price {{dataCardBS.threeyearsPriceINRTooltip}}</p>
                                                <p class="tooltiptext table-tooltip text-center dollar_price" *ngIf="showDollarPrice"> 3 years price {{dataCardBS.threeyearsPriceUSTooltip}} </p>
                                            </div>
                                            <p></p>
                                        </div>
                                        <p class="cardParaIntro d-flex align-items-center"> <img src="../../../assets/Image/userImage.svg"> {{dataCardBS.usersCount}}</p>
                                    </div>
                                    <div class=" mt-4 mb-4">
                                        <div class="plan-data-highlight"><span class="highlighted-text">Add-on Users:</span></div>
                                        <div> <p class="cardParaIntro  mt-3">Single User Only</p>
                                        </div>
                                        
                                       
                                    </div>
                                </div> <a href="https://app.dox2u.com/onboarding/create-workspace/49" target="_blank"><button class="button-blank">Try for free </button></a>
                                <div class="dCoins-highlight" (click)="scrollTodCoinsSection()"><p class="d-flex align-items-center mb-0">100 Free dCoins </p></div> 
                                <div class=" height333 position-relative">
                                    <div class="pricing-card-pointer-sec">
                                        <ul class="card-last-item p-0">
                                            <li class="d-flex align-items-baseline mb-2"><span><img src="../../../assets/Image/Tick-Right.svg" alt=""></span> <span class="fontInformPara">20 GB Storage </span></li>
                                            <li class="d-flex align-items-baseline mb-2"><span><img src="../../../assets/Image/Tick-Right.svg" alt=""></span> <span class="fontInformPara">Max File Size - 30 MB </span></li>
                                            
                                        </ul>
                                    </div>
                                    <!-- <div class="position-absolute bottom0"> <a href="tally"> <img src="../../../assets/Image/talimg.svg" width="100%"> </a> </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>   
                <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12">
                    <div class="pricing-card">
                      <div class="pricinig-card-main-content">
                          <div class="card-content">
                              <h4 class="h4 mb-2 font800px">{{datCardB.planName}}</h4>
                              <p class="cardParaIntro height5">{{datCardB.planIntro}}</p>
                              <div class="card-currency-content">
                                <div class="plan-data-highlight"><span class="highlighted-text">Plan charges:</span></div>
                                  <div id="adc6" *ngIf="showAnnualData"> <label id="Annually" name="Annually"> <span class="india_price fontsizeprice" *ngIf="showIndiaPrice"> {{datCardB.annualPMPriceINR}} <span class="per-month">/ month</span> </span> <span class="dollar_price fontsizeprice" *ngIf="showDollarPrice"> {{datCardB.annualPMPriceUS}} <span class="per-month">/ month</span> </span> </label>
                                  </div>
                                  <div id="pc6" *ngIf="showTwoYearsData"> <label id="2ndYear" name="2ndYear"> <span class="india_price fontsizeprice" *ngIf="showIndiaPrice"> <del class="delText"> {{datCardB.annualPMPriceINR}}</del> {{datCardB.twoYearPMPriceINR}} <span class="per-month">/ month</span> </span> <span class="dollar_price fontsizeprice" *ngIf="showDollarPrice"> <del class="delText "> {{datCardB.annualPMPriceUS}}</del> {{datCardB.twoYearPMPriceUS}} <span class="per-month">/ month</span> </span> </label> </div>
                                  <div id="ps6" *ngIf="showThreeYearsData"> <label id="3rdYear" name="3rdYear"> <span class="india_price fontsizeprice" *ngIf="showIndiaPrice"> <del class="delText"> {{datCardB.annualPMPriceINR}}</del> {{datCardB.threeYearPMPriceINR}} <span class="per-month">/ month</span> </span> <span class="dollar_price fontsizeprice" *ngIf="showDollarPrice"> <del class="delText"> {{datCardB.annualPMPriceUS}}</del> {{datCardB.threeYearPMPriceUS}} <span class="per-month">/ month</span> </span> </label> </div>
                                  <div class="minH">
                                      <div class=" mb-3 ">
                                          <div class="d-flex align-items-center  mb-1 position-relative ">
                                              <p class="cardParaIntro mb-0">billed </p>
                                              <div id="annualLabelBusiness_starter" class=" marginLeft26 tooltipMargin tool-tip ml-1 cursor-pointer cont "  *ngIf="showAnnualData"> <span class="border-dotted cardParaIntro">annually</span>
                                                  <p class="tooltiptext  table-tooltip text-center india_price" *ngIf="showIndiaPrice"> Annual price {{datCardB.anuualPriceINRTooltip}}</p>
                                                  <p class="tooltiptext table-tooltip text-center dollar_price" *ngIf="showDollarPrice"> Annual price {{datCardB.anuualPriceUSTooltip}} </p>
                                              </div>
                                              <div id="2yearsLabelBusiness_starter" class=" marginLeft26 tooltipMargin tool-tip ml-1 cursor-pointer cont  "  *ngIf="showTwoYearsData"> <span class="border-dotted cardParaIntro">every 2 years</span>
                                                  <p class="tooltiptext  table-tooltip text-center india_price" *ngIf="showIndiaPrice"> 2 years price {{datCardB.twoyearsPriceINRTooltip}}</p>
                                                  <p class="tooltiptext table-tooltip text-center dollar_price" *ngIf="showDollarPrice"> 2 years price {{datCardB.twoyearsPriceUSTooltip}} </p>
                                              </div>
                                              <div id="3yearsLabelBusiness_starter" class=" marginLeft26 tooltipMargin tool-tip ml-1 cursor-pointer cont  "  *ngIf="showThreeYearsData"> <span class="border-dotted cardParaIntro"> every 3 years</span>
                                                  <p class="tooltiptext  table-tooltip text-center india_price" *ngIf="showIndiaPrice"> 3 years price {{datCardB.threeyearsPriceINRTooltip}}</p>
                                                  <p class="tooltiptext table-tooltip text-center dollar_price" *ngIf="showDollarPrice"> 3 years price {{datCardB.threeyearsPriceUSTooltip}} </p>
                                              </div>
                                              <p></p>
                                          </div>
                                          <p class="cardParaIntro d-flex align-items-center"><img src="../../../assets/Image/userImage.svg"> {{datCardB.usersCount}}</p>
                                      </div>
                                      <div class=" mt-4 mb-4">
                                        <div class="plan-data-highlight"><span class="highlighted-text">Add-on Users:</span></div>
                                          <div *ngIf="showAnnualData"> <label id="Annually" name="Annually"> <span class="india_price AddOn_price" *ngIf="showIndiaPrice"> {{datCardB.addOnPriceAnnualINR}} <span class="per-month">/ month</span> </span> <span class="dollar_price AddOn_price" *ngIf="showDollarPrice"> {{datCardB.addOnPriceAnnualUS}} <span class="per-month">/ month</span> </span> </label> <!-- #endregion -->
                                          </div>
                                          <div id="pc1" *ngIf="showTwoYearsData"> <label id="2ndYear" name="2ndYear"> <span class="india_price AddOn_price" *ngIf="showIndiaPrice"> <del class="delText"> {{datCardB.addOnPriceAnnualINR}}</del> {{datCardB.addOnPriceTwoYearsINR}} <span class="per-month">/ month</span> </span> <span class="dollar_price AddOn_price" *ngIf="showDollarPrice"> <del class="delText"> {{datCardB.addOnPriceAnnualUS}}</del> {{datCardB.addOnPriceTwoYearsUS}} <span class="per-month">/ month</span> </span> </label> </div>
                                          <div id="ps1" *ngIf="showThreeYearsData"> <label id="3rdYear" name="3rdYear"> <span class="india_price AddOn_price" *ngIf="showIndiaPrice"> <del class="delText"> {{datCardB.addOnPriceAnnualINR}}</del> {{datCardB.addOnPriceThreeYearsINR}} <span class="per-month">/ month</span> </span> <span class="dollar_price AddOn_price" *ngIf="showDollarPrice"> <del class="delText"> {{datCardB.addOnPriceAnnualUS}}</del> {{datCardB.addOnPriceThreeYearsUS}} <span class="per-month">/ month</span> </span> </label> </div>
                                          <div class="d-flex align-items-center  mb-1 position-relative ">
                                              <p class="cardParaIntro  ">billed </p>
                                              <div id="annualLabel" class=" marginLeft26 tool-tip ml-1 tooltipMargin cursor-pointer cont "  *ngIf="showAnnualData"> <span class="border-dotted cardParaIntro">annually</span>
                                                  <p class="tooltiptext  table-tooltip text-center india_price" *ngIf="showIndiaPrice"> Annual price ₹999</p>
                                                  <p class="tooltiptext table-tooltip text-center dollar_price" *ngIf="showDollarPrice"> Annual price $15 </p>
                                              </div>
                                              <div id="2yearsLabel" class=" marginLeft26 tool-tip ml-1 tooltipMargin cursor-pointer cont  " *ngIf="showTwoYearsData"> <span class="border-dotted cardParaIntro">every 2 years</span>
                                                  <p class="tooltiptext  table-tooltip text-center india_price" *ngIf="showIndiaPrice"> 2 years price ₹1,799</p>
                                                  <p class="tooltiptext table-tooltip text-center dollar_price" *ngIf="showDollarPrice"> 2 years price $27 </p>
                                              </div>
                                              <div id="3yearsLabel" class=" marginLeft26 tool-tip ml-1 tooltipMargin cursor-pointer cont  "  *ngIf="showThreeYearsData"> <span class="border-dotted cardParaIntro">every 3 years</span>
                                                  <p class="tooltiptext  table-tooltip text-center india_price" *ngIf="showIndiaPrice"> 3 years price ₹2,548</p>
                                                  <p class="tooltiptext table-tooltip text-center dollar_price" *ngIf="showDollarPrice"> 3 years price $37 </p>
                                              </div>
                                              <p></p> <!-- <p class="cardParaIntro monthData permonthData">per month</p> -->
                                          </div>
                                      </div>
                                  </div> <a href="https://app.dox2u.com/onboarding/create-workspace/48" target="_blank"><button class="button-blank">Try for free </button></a>
                                 <div class="dCoins-highlight" (click)="scrollTodCoinsSection()"><p class="d-flex align-items-center mb-0">150 Free dCoins </p></div> 
                                  <div class=" height333 position-relative">
                                      <div class="pricing-card-pointer-sec">
                                          <ul class="card-last-item p-0">
                                              <li class="d-flex align-items-baseline mb-2"><span><img src="../../../assets/Image/Tick-Right.svg" alt=""></span> <span class="fontInformPara">50 GB / User Storage </span></li>
                                              <li class="d-flex align-items-baseline mb-2"><span><img src="../../../assets/Image/Tick-Right.svg" alt=""></span> <span class="fontInformPara">Max File Size - 150 MB </span></li>
                                              <li class="d-flex align-items-baseline mb-2"><span><img src="../../../assets/Image/Tick-Right.svg" alt=""></span> <span class="fontInformPara">Doc Preview </span></li>
                                              
                                          </ul>
                                      </div>
                                      <div class="position-absolute bottom0"> <a href="tally"> <img src="../../../assets/Image/talimg.svg" width="100%"> </a> </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  </div> 
                  <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12">
                    <div class="pricing-card">
                        <div class="mPop">MOST POPULAR</div>
                      <div class="pricinig-card-main-content">
                          <div class="card-content">
                              <h4 class="h4 mb-2 font800px">{{dataCardBP.planName}}</h4>
                              <p class="cardParaIntro height5">{{dataCardBP.planIntro}}</p>
                              <div class="card-currency-content">
                                <div class="plan-data-highlight"><span class="highlighted-text">Plan charges:</span></div>
                                  <div id="adc6" *ngIf="showAnnualData"> <label id="Annually" name="Annually"> <span class="india_price fontsizeprice" *ngIf="showIndiaPrice"> {{dataCardBP.annualPMPriceINR}} <span class="per-month">/ month</span> </span> <span class="dollar_price fontsizeprice" *ngIf="showDollarPrice"> {{dataCardBP.annualPMPriceUS}} <span class="per-month">/ month</span> </span> </label>
                                  </div>
                                  <div id="pc6" *ngIf="showTwoYearsData"> <label id="2ndYear" name="2ndYear"> <span class="india_price fontsizeprice" *ngIf="showIndiaPrice"> <del class="delText"> {{dataCardBP.annualPMPriceINR}}</del> {{dataCardBP.twoYearPMPriceINR}} <span class="per-month">/ month</span> </span> <span class="dollar_price fontsizeprice" *ngIf="showDollarPrice"> <del class="delText "> {{dataCardBP.annualPMPriceUS}}</del> {{dataCardBP.twoYearPMPriceUS}} <span class="per-month">/ month</span> </span> </label> </div>
                                  <div id="ps6" *ngIf="showThreeYearsData"> <label id="3rdYear" name="3rdYear"> <span class="india_price fontsizeprice" *ngIf="showIndiaPrice"> <del class="delText"> {{dataCardBP.annualPMPriceINR}}</del> {{dataCardBP.threeYearPMPriceINR}} <span class="per-month">/ month</span> </span> <span class="dollar_price fontsizeprice" *ngIf="showDollarPrice"> <del class="delText"> {{dataCardBP.annualPMPriceUS}}</del> {{dataCardBP.threeYearPMPriceUS}} <span class="per-month">/ month</span> </span> </label> </div>
                                  <div class="minH">
                                      <div class=" mb-3 ">
                                          <div class="d-flex align-items-center  mb-1 position-relative ">
                                              <p class="cardParaIntro mb-0">billed </p>
                                              <div id="annualLabelBusiness_starter" class=" marginLeft26 tooltipMargin tool-tip ml-1 cursor-pointer cont "  *ngIf="showAnnualData"> <span class="border-dotted cardParaIntro">annually</span>
                                                  <p class="tooltiptext  table-tooltip text-center india_price" *ngIf="showIndiaPrice"> Annual price {{dataCardBP.anuualPriceINRTooltip}}</p>
                                                  <p class="tooltiptext table-tooltip text-center dollar_price" *ngIf="showDollarPrice"> Annual price {{dataCardBP.anuualPriceUSTooltip}} </p>
                                              </div>
                                              <div id="2yearsLabelBusiness_starter" class=" marginLeft26 tooltipMargin tool-tip ml-1 cursor-pointer cont  "  *ngIf="showTwoYearsData"> <span class="border-dotted cardParaIntro">every 2 years</span>
                                                  <p class="tooltiptext  table-tooltip text-center india_price" *ngIf="showIndiaPrice"> 2 years price {{dataCardBP.twoyearsPriceINRTooltip}}</p>
                                                  <p class="tooltiptext table-tooltip text-center dollar_price" *ngIf="showDollarPrice"> 2 years price {{dataCardBP.twoyearsPriceUSTooltip}} </p>
                                              </div>
                                              <div id="3yearsLabelBusiness_starter" class=" marginLeft26 tooltipMargin tool-tip ml-1 cursor-pointer cont  "  *ngIf="showThreeYearsData"> <span class="border-dotted cardParaIntro"> every 3 years</span>
                                                  <p class="tooltiptext  table-tooltip text-center india_price" *ngIf="showIndiaPrice"> 3 years price {{dataCardBP.threeyearsPriceINRTooltip}}</p>
                                                  <p class="tooltiptext table-tooltip text-center dollar_price" *ngIf="showDollarPrice"> 3 years price {{dataCardBP.threeyearsPriceUSTooltip}} </p>
                                              </div>
                                              <p></p>
                                          </div>
                                          <p class="cardParaIntro d-flex align-items-center"><img src="../../../assets/Image/userImage.svg"> {{dataCardBP.usersCount}}</p>
                                      </div>
                                      <div class=" mt-4 mb-4">
                                        <div class="plan-data-highlight"><span class="highlighted-text">Add-on Users:</span></div>
                                          <div *ngIf="showAnnualData"> <label id="Annually" name="Annually"> <span class="india_price AddOn_price" *ngIf="showIndiaPrice"> {{dataCardBP.addOnPriceAnnualINR}} <span class="per-month">/ month</span> </span> <span class="dollar_price AddOn_price" *ngIf="showDollarPrice"> {{dataCardBP.addOnPriceAnnualUS}} <span class="per-month">/ month</span> </span> </label> <!-- #endregion -->
                                          </div>
                                          <div id="pc1" *ngIf="showTwoYearsData"> <label id="2ndYear" name="2ndYear"> <span class="india_price AddOn_price" *ngIf="showIndiaPrice"> <del class="delText"> {{dataCardBP.addOnPriceAnnualINR}}</del> {{dataCardBP.addOnPriceTwoYearsINR}} <span class="per-month">/ month</span> </span> <span class="dollar_price AddOn_price" *ngIf="showDollarPrice"> <del class="delText"> {{dataCardBP.addOnPriceAnnualUS}}</del> {{dataCardBP.addOnPriceTwoYearsUS}} <span class="per-month">/ month</span> </span> </label> </div>
                                          <div id="ps1" *ngIf="showThreeYearsData"> <label id="3rdYear" name="3rdYear"> <span class="india_price AddOn_price" *ngIf="showIndiaPrice"> <del class="delText"> {{dataCardBP.addOnPriceAnnualINR}}</del> {{dataCardBP.addOnPriceThreeYearsINR}} <span class="per-month">/ month</span> </span> <span class="dollar_price AddOn_price" *ngIf="showDollarPrice"> <del class="delText"> {{dataCardBP.addOnPriceAnnualUS}}</del> {{dataCardBP.addOnPriceThreeYearsUS}} <span class="per-month">/ month</span> </span> </label> </div>
                                          <div class="d-flex align-items-center  mb-1 position-relative ">
                                              <p class="cardParaIntro  ">billed </p>
                                              <div id="annualLabel" class=" marginLeft26 tool-tip ml-1 tooltipMargin cursor-pointer cont "  *ngIf="showAnnualData"> <span class="border-dotted cardParaIntro">annually</span>
                                                  <p class="tooltiptext  table-tooltip text-center india_price" *ngIf="showIndiaPrice"> Annual price ₹1,599</p>
                                                  <p class="tooltiptext table-tooltip text-center dollar_price" *ngIf="showDollarPrice"> Annual price $23 </p>
                                              </div>
                                              <div id="2yearsLabel" class=" marginLeft26 tool-tip ml-1 tooltipMargin cursor-pointer cont  " *ngIf="showTwoYearsData"> <span class="border-dotted cardParaIntro">every 2 years</span>
                                                  <p class="tooltiptext  table-tooltip text-center india_price" *ngIf="showIndiaPrice"> 2 years price ₹2,879</p>
                                                  <p class="tooltiptext table-tooltip text-center dollar_price" *ngIf="showDollarPrice"> 2 years price $42 </p>
                                              </div>
                                              <div id="3yearsLabel" class=" marginLeft26 tool-tip ml-1 tooltipMargin cursor-pointer cont  "  *ngIf="showThreeYearsData"> <span class="border-dotted cardParaIntro">every 3 years</span>
                                                  <p class="tooltiptext  table-tooltip text-center india_price" *ngIf="showIndiaPrice"> 3 years price ₹4,078</p>
                                                  <p class="tooltiptext table-tooltip text-center dollar_price" *ngIf="showDollarPrice"> 3 years price $59 </p>
                                              </div>
                                              <p></p> <!-- <p class="cardParaIntro monthData permonthData">per month</p> -->
                                          </div>
                                      </div>
                                  </div> <a href="https://app.dox2u.com/onboarding/create-workspace/47" target="_blank"><button class="button-blank">Try for free </button></a>
                                  <div class="dCoins-highlight" (click)="scrollTodCoinsSection()"><p class="d-flex align-items-center mb-0">200 Free dCoins </p></div> 
                                  <div class=" height333 position-relative">
                                      <div class="pricing-card-pointer-sec">
                                          <ul class="card-last-item p-0">
                                            <li class="d-flex align-items-baseline mb-2"><span><img src="../../../assets/Image/Tick-Right.svg" alt=""></span> <span class="fontInformPara">200 GB / User Storage </span></li>
                                            <li class="d-flex align-items-baseline mb-2"><span><img src="../../../assets/Image/Tick-Right.svg" alt=""></span> <span class="fontInformPara">Max File Size - 500 MB </span></li>
                                            <li class="d-flex align-items-baseline mb-2"><span><img src="../../../assets/Image/Tick-Right.svg" alt=""></span> <span class="fontInformPara">Search using content from Scanned docs </span></li>
                                            
                                          </ul>
                                      </div>
                                      <div class="position-absolute bottom0"> <a href="tally"> <img src="../../../assets/Image/talimg.svg" width="100%"> </a> </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  </div> 
                  <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12">
                    <div class="pricing-card">
                      <div class="pricinig-card-main-content">
                          <div class="card-content">
                              <h4 class="h4 mb-2 font800px">{{dataCardT.planName}}</h4>
                              <p class="cardParaIntro height5">{{dataCardT.planIntro}}</p>
                              <div class="card-currency-content">
                                <div class="plan-data-highlight"><span class="highlighted-text">Plan charges:</span></div>
                                  <div id="adc6" *ngIf="showAnnualData"> <label id="Annually" name="Annually"> <span class="india_price fontsizeprice" *ngIf="showIndiaPrice"> {{dataCardT.annualPMPriceINR}} <span class="per-month">/ month</span> </span> <span class="dollar_price fontsizeprice" *ngIf="showDollarPrice"> {{dataCardT.annualPMPriceUS}} <span class="per-month">/ month</span> </span> </label>
                                  </div>
                                  <div id="pc6" *ngIf="showTwoYearsData"> <label id="2ndYear" name="2ndYear"> <span class="india_price fontsizeprice" *ngIf="showIndiaPrice"> <del class="delText"> {{dataCardT.annualPMPriceINR}}</del> {{dataCardT.twoYearPMPriceINR}} <span class="per-month">/ month</span> </span> <span class="dollar_price fontsizeprice" *ngIf="showDollarPrice"> <del class="delText "> {{dataCardT.annualPMPriceUS}}</del> {{dataCardT.twoYearPMPriceUS}} <span class="per-month">/ month</span> </span> </label> </div>
                                  <div id="ps6" *ngIf="showThreeYearsData"> <label id="3rdYear" name="3rdYear"> <span class="india_price fontsizeprice" *ngIf="showIndiaPrice"> <del class="delText"> {{dataCardT.annualPMPriceINR}}</del> {{dataCardT.threeYearPMPriceINR}} <span class="per-month">/ month</span> </span> <span class="dollar_price fontsizeprice" *ngIf="showDollarPrice"> <del class="delText"> {{dataCardT.annualPMPriceUS}}</del> {{dataCardT.threeYearPMPriceUS}} <span class="per-month">/ month</span> </span> </label> </div>
                                  <div class="minH">
                                      <div class=" mb-3 ">
                                          <div class="d-flex align-items-center  mb-1 position-relative ">
                                              <p class="cardParaIntro mb-0">billed </p>
                                              <div id="annualLabelBusiness_starter" class=" marginLeft26 tooltipMargin tool-tip ml-1 cursor-pointer cont "  *ngIf="showAnnualData"> <span class="border-dotted cardParaIntro">annually</span>
                                                  <p class="tooltiptext teamsPlanTooltip  table-tooltip text-center india_price" *ngIf="showIndiaPrice"> Annual price {{dataCardT.anuualPriceINRTooltip}}</p>
                                                  <p class="tooltiptext teamsPlanTooltip table-tooltip text-center dollar_price" *ngIf="showDollarPrice"> Annual price {{dataCardT.anuualPriceUSTooltip}} </p>
                                              </div>
                                              <div id="2yearsLabelBusiness_starter" class="tool-tip ml-1 tooltipMargin cursor-pointer cont marginLeft26 "  *ngIf="showTwoYearsData"> <span class="border-dotted cardParaIntro">every 2 years</span>
                                                  <p class="tooltiptext teamsPlanTooltip  table-tooltip text-center india_price" *ngIf="showIndiaPrice"> 2 years price {{dataCardT.twoyearsPriceINRTooltip}}</p>
                                                  <p class="tooltiptext teamsPlanTooltip table-tooltip text-center dollar_price" *ngIf="showDollarPrice"> 2 years price {{dataCardT.twoyearsPriceUSTooltip}} </p>
                                              </div>
                                              <div id="3yearsLabelBusiness_starter" class="tool-tip ml-1 tooltipMargin cursor-pointer cont marginLeft26 "  *ngIf="showThreeYearsData"> <span class="border-dotted cardParaIntro"> every 3 years</span>
                                                  <p class="tooltiptext teamsPlanTooltip  table-tooltip text-center india_price" *ngIf="showIndiaPrice"> 3 years price {{dataCardT.threeyearsPriceINRTooltip}}</p>
                                                  <p class="tooltiptext teamsPlanTooltip table-tooltip text-center dollar_price" *ngIf="showDollarPrice"> 3 years price {{dataCardT.threeyearsPriceUSTooltip}} </p>
                                              </div>
                                              <p></p>
                                          </div>
                                          <p class="cardParaIntro d-flex align-items-center"><img src="../../../assets/Image/userImage.svg"> {{dataCardT.usersCount}}</p>
                                      </div>
                                      <div class=" mt-4 mb-4">
                                        <div class="plan-data-highlight"><span class="highlighted-text">Add-on Users:</span></div>
                                          <div *ngIf="showAnnualData"> <label id="Annually" name="Annually"> <span class="india_price AddOn_price" *ngIf="showIndiaPrice"> {{dataCardT.addOnPriceAnnualINR}} <span class="per-month">/ month</span> </span> <span class="dollar_price AddOn_price" *ngIf="showDollarPrice"> {{dataCardT.addOnPriceAnnualUS}} <span class="per-month">/ month</span> </span> </label> <!-- #endregion -->
                                          </div>
                                          <div id="pc1" *ngIf="showTwoYearsData"> <label id="2ndYear" name="2ndYear"> <span class="india_price AddOn_price" *ngIf="showIndiaPrice"> <del class="delText"> {{dataCardT.addOnPriceAnnualINR}}</del> {{dataCardT.addOnPriceTwoYearsINR}} <span class="per-month">/ month</span> </span> <span class="dollar_price AddOn_price" *ngIf="showDollarPrice"> <del class="delText"> {{dataCardT.addOnPriceAnnualUS}}</del> {{dataCardT.addOnPriceTwoYearsUS}} <span class="per-month">/ month</span> </span> </label> </div>
                                          <div id="ps1" *ngIf="showThreeYearsData"> <label id="3rdYear" name="3rdYear"> <span class="india_price AddOn_price" *ngIf="showIndiaPrice"> <del class="delText"> {{dataCardT.addOnPriceAnnualINR}}</del> {{dataCardT.addOnPriceThreeYearsINR}} <span class="per-month">/ month</span> </span> <span class="dollar_price AddOn_price" *ngIf="showDollarPrice"> <del class="delText"> {{dataCardT.addOnPriceAnnualUS}}</del> {{dataCardT.addOnPriceThreeYearsUS}} <span class="per-month">/ month</span> </span> </label> </div>
                                          <div class="d-flex align-items-center  mb-1 position-relative ">
                                              <p class="cardParaIntro  ">billed </p>
                                              <div id="annualLabel" class=" marginLeft26 tool-tip ml-1 tooltipMargin cursor-pointer cont "  *ngIf="showAnnualData"> <span class="border-dotted cardParaIntro">annually</span>
                                                  <p class="tooltiptext teamsPlanTooltip  table-tooltip text-center india_price" *ngIf="showIndiaPrice"> Annual price ₹2,199</p>
                                                  <p class="tooltiptext teamsPlanTooltip table-tooltip text-center dollar_price" *ngIf="showDollarPrice"> Annual price $32 </p>
                                              </div>
                                              <div id="2yearsLabel" class=" marginLeft26 tool-tip ml-1 tooltipMargin cursor-pointer cont  " *ngIf="showTwoYearsData"> <span class="border-dotted cardParaIntro">every 2 years</span>
                                                  <p class="tooltiptext teamsPlanTooltip  table-tooltip text-center india_price" *ngIf="showIndiaPrice"> 2 years price ₹3,959</p>
                                                  <p class="tooltiptext teamsPlanTooltip table-tooltip text-center dollar_price" *ngIf="showDollarPrice"> 2 years price $58 </p>
                                              </div>
                                              <div id="3yearsLabel" class=" marginLeft26 tool-tip ml-1 tooltipMargin cursor-pointer cont  "  *ngIf="showThreeYearsData"> <span class="border-dotted cardParaIntro">every 3 years</span>
                                                  <p class="tooltiptext teamsPlanTooltip  table-tooltip text-center india_price" *ngIf="showIndiaPrice"> 3 years price ₹5,608</p>
                                                  <p class="tooltiptext teamsPlanTooltip table-tooltip text-center dollar_price" *ngIf="showDollarPrice"> 3 years price $82 </p>
                                              </div>
                                              <p></p> <!-- <p class="cardParaIntro monthData permonthData">per month</p> -->
                                          </div>
                                      </div>
                                  </div> <a href="https://app.dox2u.com/onboarding/create-workspace/46" target="_blank"><button class="button-blank">Try for free </button></a>
                                  <div class="dCoins-highlight" (click)="scrollTodCoinsSection()"><p class="d-flex align-items-center mb-0">250 Free dCoins </p></div> 
                                  <div class=" height333 position-relative">
                                      <div class="pricing-card-pointer-sec">
                                          <ul class="card-last-item p-0">
                                              <li class="d-flex align-items-baseline mb-2"><span><img src="../../../assets/Image/Tick-Right.svg" alt=""></span> <span class="fontInformPara">500 GB / User Storage </span></li>
                                              <li class="d-flex align-items-baseline mb-2"><span><img src="../../../assets/Image/Tick-Right.svg" alt=""></span> <span class="fontInformPara">Unlimited File Size </span></li>
                                              <li class="d-flex align-items-baseline mb-2"><span><img src="../../../assets/Image/Tick-Right.svg" alt=""></span> <span class="fontInformPara">IP Whitelisting </span></li>
                                              <li class="d-flex align-items-baseline mb-2"><span><img src="../../../assets/Image/Tick-Right.svg" alt=""></span> <span class="fontInformPara">Integrations </span></li>
                                              
                                          </ul>
                                      </div>
                                      <div class="position-absolute bottom0"> <a href="tally"> <img src="../../../assets/Image/talimg.svg" width="100%"> </a> </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  </div> 
                
                                                                                        
                </div>
            </div>
        </div>
    </div>
</section>

<section class="common-padd  p-0">
    <div class="container ">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
                <a style="text-decoration: none !important;" (click)="expandAccordion()"><p class="text-center p-head"> How does dox2U's billing work?</p></a>
                <p class=" text-center local-Tax">Local taxes (VAT, GST, etc.) will be charged in
                    addition to the prices mentioned, where applicable</p>
            </div>
        </div>
    </div>
</section>

<!-- <section class="plan-feat common-padd" *ngIf="!isLaunchTime">
    <div class="container containerOverwrite">
        <div class="row paddingComm">
            <div class="paddingCommDiv d-flex justify-content-center flex-column  col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12  ">
                <p class="common-para text-center mb-3 aboveplan">All the above Plans include these features</p>
                <div class="  col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12   ">
                    <div class="row align-items-start justify-content-center">
                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 mx-auto ">
                            <div class="d-flex flex-column ">
                                <div>
                                    <ul class="pl-0">
                                    <li class="d-flex align-items-baseline mb-3">
                                        <span><img src="../../../assets/Image/Tick-Right.svg" alt=""></span>
                                        <div class="tool-tip m-right ">
                                            <span class=" fontInformPara border-dotted cursor-pointer">Custom dox2U
                                                subdomain for your team
                                            </span>
                                            <p class="tooltiptext table-tooltip">You can create a custom sub-domain
                                                as a specially designated host-name for your team, for example
                                                <br><br>
                                                <a>YourName.dox2u.com</a>
                                            </p>
                                        </div>
                                    </li>
                                    <li class="d-flex align-items-baseline mb-3">
                                        <span><img src="../../../assets/Image/Tick-Right.svg" alt=""></span>
                                        <div class="tool-tip  m-right">
                                            <span class=" fontInformPara border-dotted cursor-pointer">
                                            Plugin for Tally ERP
                                            </span>
                                            <p class="tooltiptext table-tooltip">Plugin for Tally.ERP allowing documents to be attahced to Tally Vouchers and synced with dox2U for archival. 
                                            </p>
                                        </div>
                                    </li>
                                    <li class="d-flex align-items-baseline mb-3">
                                        <span><img src="../../../assets/Image/Tick-Right.svg" alt=""></span>
                                        <div class="tool-tip  m-right">
                                            <span class=" fontInformPara border-dotted cursor-pointer">Smart Cabinet: Intelligent retrieval
                                            </span>
                                            <p class="tooltiptext table-tooltip">Organized view of all documents in your workspace for easy &amp; intuitive retrieval
                                            </p>
                                        </div>
                                    </li>  
                                    <li class="d-flex align-items-baseline mb-3">
                                        <span><img src="../../../assets/Image/Tick-Right.svg" alt=""></span>
                                        <div class="tool-tip m-right ">
                                            <span class=" fontInformPara border-dotted cursor-pointer">Data Encryption (both in transit &amp; at rest)
                                            </span>
                                            <p class="tooltiptext table-tooltip">We use time-tested and highly secure AES-256 and TLS encryptions ensuring your content is secure at all times</p>
                                        </div>
                                    </li> 
                                </ul>                             
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 mx-auto ">
                            <div class="d-flex flex-column">
                                <div>     
                                    <ul class="pl-0">                              
                                    <li class="d-flex align-items-baseline mb-3">
                                        <span><img src="../../../assets/Image/Tick-Right.svg" alt=""></span>
                                        <div class="tool-tip  m-right">
                                            <span class=" fontInformPara border-dotted cursor-pointer">Tagging &amp; Indexing
                                            </span>
                                            <p class="tooltiptext table-tooltip cursor-pointer">Add metadata tags using our innovative templates for standardizing how you want your documents structured</p>

                                        </div>
                                    </li>
                                    <li class="d-flex align-items-baseline mb-3">
                                        <span><img src="../../../assets/Image/Tick-Right.svg" alt=""></span>
                                        <div class="tool-tip  m-right">
                                            <span class=" fontInformPara border-dotted cursor-pointer">Search on Tags, Metadata and Filename
                                            </span>
                                            <p class="tooltiptext table-tooltip cursor-pointer">
                                            Use content from Non-Image Formats (like .docx files) to retrieve them in seconds
                                            </p>

                                        </div>
                                    </li>
                                    <li class="d-flex align-items-baseline mb-3">
                                        <span><img src="../../../assets/Image/Tick-Right.svg" alt=""></span>

                                        <div class="tool-tip  m-right">
                                            <span class=" fontInformPara border-dotted cursor-pointer"> Secure Guest Sharing
                                            </span>
                                            <p class="tooltiptext table-tooltip">Use dox2U's Guest Sharing to securely share documents with external stakeholders like vendors, customers, partners and more!</p>

                                        </div>
                                    </li>
                                    <li class="d-flex align-items-baseline mb-3">
                                        <span><img src="../../../assets/Image/Tick-Right.svg" alt=""></span>

                                        <div class="tool-tip  m-right">
                                            <span class=" fontInformPara border-dotted cursor-pointer"> Search on content from Non-Image Formats
                                            </span>
                                            <p class="tooltiptext table-tooltip">Use content from Non-Image Formats (like .docx files) to retrieve them in seconds</p>

                                        </div>
                                    </li>
                                </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 mx-auto ">
                            <div class="d-flex flex-column ">
                                <div>
                                    <ul class="pl-0">
                                    <li class="d-flex align-items-baseline mb-3">
                                        <span><img src="../../../assets/Image/Tick-Right.svg" alt=""></span>

                                        <div class="tool-tip  m-right">
                                            <span class=" fontInformPara border-dotted cursor-pointer"> Sharing over WhatsApp
                                            </span>
                                            <p class="tooltiptext table-tooltip">Share documents with Guests over WhatsApp with OTP & duration-controlled access.</p>

                                        </div>
                                    </li>
                                    <li class="d-flex align-items-baseline mb-3">
                                        <span><img src="../../../assets/Image/Tick-Right.svg" alt=""></span>

                                        <div class="tool-tip  m-right">
                                            <span class=" fontInformPara border-dotted cursor-pointer"> Free Plugin Viewer
                                            </span>
                                            <p class="tooltiptext table-tooltip">Tally Viewers can access documents through Tally but will not be able to log in to your Workspace.</p>

                                        </div>
                                    </li>
                                    
                                </ul>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<section class="plan-feat common-padd" *ngIf="isLaunchTime">
    <div class="container containerOverwrite">
        <div class="row paddingComm">
            <div class="paddingCommDiv d-flex justify-content-center flex-column  col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12  ">
                <p class="common-para text-center mb-3 aboveplan">All the above Plans include these features</p>
                <div class="  col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12   ">
                    <div class="row align-items-start justify-content-center">
                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 mx-auto ">
                            <div class="d-flex flex-column ">
                                <div>
                                    <ul class="pl-0">
                                    <li class="d-flex align-items-baseline mb-3">
                                        <span><img src="../../../assets/Image/Tick-Right.svg" alt=""></span>
                                        <div class="tool-tip  m-right">
                                            <span class=" fontInformPara border-dotted cursor-pointer">Smart Cabinet: Intelligent retrieval
                                            </span>
                                            <p class="tooltiptext table-tooltip">Organized view of all documents in your workspace for easy &amp; intuitive retrieval
                                            </p>
                                        </div>
                                    </li>  
                                    <li class="d-flex align-items-baseline mb-3">
                                        <span><img src="../../../assets/Image/Tick-Right.svg" alt=""></span>
                                        <div class="tool-tip m-right ">
                                            <span class=" fontInformPara border-dotted cursor-pointer">Data Encryption (both in transit &amp; at rest)
                                            </span>
                                            <p class="tooltiptext table-tooltip">We use time-tested and highly secure AES-256 and TLS encryptions ensuring your content is secure at all times</p>
                                        </div>
                                    </li>   
                                    <li class="d-flex align-items-baseline mb-3">
                                        <span><img src="../../../assets/Image/Tick-Right.svg" alt=""></span>
                                        <div class="tool-tip  m-right">
                                            <span class=" fontInformPara border-dotted cursor-pointer">Tagging &amp; Indexing
                                            </span>
                                            <p class="tooltiptext table-tooltip cursor-pointer">Add metadata tags using our innovative templates for standardizing how you want your documents structured</p>

                                        </div>
                                    </li> 
                                </ul>                          
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 mx-auto ">
                            <div class="d-flex flex-column">
                                <div>  
                                    <ul class="pl-0">                                 
                                    <li class="d-flex align-items-baseline mb-3">
                                        <span><img src="../../../assets/Image/Tick-Right.svg" alt=""></span>

                                        <div class="tool-tip  m-right">
                                            <span class=" fontInformPara border-dotted cursor-pointer"> Search on content from Non-Image Formats
                                            </span>
                                            <p class="tooltiptext table-tooltip">Use content from Non-Image Formats (like .docx files) to retrieve them in seconds</p>

                                        </div>
                                    </li>
                                    <li class="d-flex align-items-baseline mb-3">
                                        <span><img src="../../../assets/Image/Tick-Right.svg" alt=""></span>

                                        <div class="tool-tip  m-right">
                                            <span class=" fontInformPara border-dotted cursor-pointer"> Secure Guest Sharing
                                            </span>
                                            <p class="tooltiptext table-tooltip">Use dox2U's Guest Sharing to securely share documents with external stakeholders like vendors, customers, partners and more!</p>

                                        </div>
                                    </li>
                                    <li class="d-flex align-items-baseline mb-3">
                                        <span><img src="../../../assets/Image/Tick-Right.svg" alt=""></span>

                                        <div class="tool-tip  m-right">
                                            <span class=" fontInformPara border-dotted cursor-pointer"> Sharing over WhatsApp
                                            </span>
                                            <p class="tooltiptext table-tooltip">Share documents with Guests over WhatsApp with OTP & duration-controlled access.</p>

                                        </div>
                                    </li>
                                </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 mx-auto ">
                            <div class="d-flex flex-column ">
                                <div>
                                    <ul class="pl-0">
                                    <li class="d-flex align-items-baseline mb-3">
                                        <span><img src="../../../assets/Image/Tick-Right.svg" alt=""></span>

                                        <div class="tool-tip  m-right">
                                            <span class=" fontInformPara border-dotted cursor-pointer"> Plugin for Tally
                                            </span>
                                            <p class="tooltiptext table-tooltip">Plugin for Tally allowing documents to be attached to Tally Vouchers and synced with dox2U for archival.</p>

                                        </div>
                                    </li>
                                    <li class="d-flex align-items-baseline mb-3">
                                        <span><img src="../../../assets/Image/Tick-Right.svg" alt=""></span>

                                        <div class="tool-tip  m-right">
                                            <span class=" fontInformPara border-dotted cursor-pointer"> Free Plugin Viewer
                                            </span>
                                            <p class="tooltiptext table-tooltip">Free Viewers on Tally Plugin that can access documents from Tally</p>

                                        </div>
                                    </li>
                                    <li class="d-flex align-items-baseline mb-3">
                                        <span><img src="../../../assets/Image/Tick-Right.svg" alt=""></span>

                                        <div class="tool-tip  m-right">
                                            <span class=" fontInformPara border-dotted cursor-pointer"> Tally Backup
                                            </span>
                                            <p class="tooltiptext table-tooltip">Schedule automatic Backups for your Tally data to your secure cloud workspace</p>

                                        </div>
                                    </li>
                                </ul>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="plan-feat common-padd dcoins-section" id="dCoinsSection">
    <div class="container containerOverwrite">
        <div class="row paddingComm padding36">
            <div class="paddingCommDiv displayChange px-0 col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 px-0">
                <p class="common-para text-left dCoins-header">Top up your Wallet with dCoins</p>
                <p class="cardParaIntro" style="max-width: 500px;">
                    dCoins are needed for using some of our pay-per-use services 
                    (such as WhatsApp messaging)    
                </p>
                <li class="d-flex align-items-baseline mb-3">
                    <span><img src="../../../assets/Image/Tick-Right.svg" alt=""></span>
                    <div class="tool-tip  m-right">
                        <span class=" fontInformPara cursor-pointer">
                            1 WhatsApp Message = 0.55 dCoins
                        </span>
                       
                    </div>
                </li>
            </div>

            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <table class="dcoins-table" *ngIf="showIndiaPrice">
                    <thead>
                      <tr>
                        <th scope="col" style="width: 54%">dCoins Packs</th>
                        <th class="vertical-line" scope="col">Price (INR)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>500 dCoins</td>
                        <td class="vertical-line">₹ 500/-</td>
                      </tr>
                      <tr>
                        <td>1000 dCoins</td>
                        <td class="vertical-line">₹ 950/-</td>
                      </tr>
                      <tr>
                        <td>5000 dCoins</td>
                        <td class="vertical-line">₹ 4,650/-</td>
                      </tr>
                    </tbody>
                  </table>

                  <table class="dcoins-table" *ngIf="showDollarPrice">
                    <thead>
                      <tr>
                        <th scope="col" style="width: 54%">dCoins Packs</th>
                        <th class="vertical-line" scope="col">Price (USD)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>500 dCoins</td>
                        <td class="vertical-line">$ 7.25</td>
                      </tr>
                      <tr>
                        <td>1000 dCoins</td>
                        <td class="vertical-line">$ 13.75</td>
                      </tr>
                      <tr>
                        <td>5000 dCoins</td>
                        <td class="vertical-line">$ 67.25</td>
                      </tr>
                    </tbody>
                  </table>
                  
            </div>
            </div>
        </div>
    </div>
</section>
<!-- <section class="compare-plans " *ngIf="!isLaunchTime">
    <div class="container special containerOverwrite ">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto p-0">
            <div class="pricing-banner-content mb-4 pb-3">
                                    <h1 class="heading-1 text-center pb-1 mb-2" style="padding-top:56px">Feature Comparison
                                    </h1>
                                </div>

                 new code here
                <div class="card card-body tablemargin">
                        <div class="pricing-table">
                            <table class="feature-table tbl" id="expandable-table">
                             <thead>
                                <tr class=" compare-plans-card ">
                                <td><span class="compare-plan-heading">Features</span></td>

                                    <td><span class="compare-plan-heading">Business Starter</span></td>
                                    <td><span class="compare-plan-heading">Business</span></td>
                                    <td> <span class="compare-plan-heading">Business Plus</span></td>
                                    <td> <span class="compare-plan-heading">Teams</span></td>
                                </tr>
                                </thead>

                                <tbody>

                               

                                <tr class="row-white rBorder">
                                    <td class="feature-content cursor-pointer">

                                        <div class="tool-tip">
                                            <span class="">Storage</span>
                                             <p class="tooltiptext table-tooltip">Each dox2U page (dPage) is equivalent to
                                        500 KB, which is the average size of an A4-sized page scanned @ 300dpi. We
                                        bill in this convenient unit instead of KB / MB / GB</p>

                                        </div>
                                    </td>

                                    <td><span class="table-heading mb-0">20 GB</span></td>
                                    <td><span class="table-heading mb-0">50 GB/user</span></td>
                                    <td><span class="table-heading mb-0">200 GB/user</span></td>
                                    <td><span class="table-heading mb-0">500 GB/user</span></td>

                                </tr>

                                <tr class="row-white rBorder">
                                    <td class="feature-content cursor-pointer">

                                        <div class="tool-tip">
                                            <span class="">Maximum size per document</span>
                                             <p class="tooltiptext table-tooltip">Each dox2U page (dPage) is equivalent to
                                        500 KB, which is the average size of an A4-sized page scanned @ 300dpi. We
                                        bill in this convenient unit instead of KB / MB / GB</p>

                                        </div>
                                    </td>

                                    <td><span class="table-heading mb-0">30 MB</span></td>
                                    <td><span class="table-heading mb-0">150 MB</span></td>
                                    <td><span class="table-heading mb-0">500 MB</span></td>
                                    <td><span class="table-heading mb-0">1 GB</span></td>

                                </tr>
                               
                                <tr class="rBorder" [ngClass]="changeOpacity ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <span>Minimum Users</span>
                                    </td>

                                    <td> <span class="table-heading">1</span></td>
                                    <td><span class="table-heading">1</span></td>
                                    <td> <span class="table-heading">1</span></td>
                                    <td><span class="table-heading mb-0">1</span></td>
                                </tr>

                                <tr class="row-white rBorder" [ngClass]="changeOpacity ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">Maximum Users</td>

                                    <td><span class="table-heading">1</span></td>
                                    <td> <span class="table-heading">Unlimited</span></td>
                                    <td><span class="table-heading">Unlimited</span></td>
                                    <td><span class="table-heading mb-0">Unlimited</span></td>
                                </tr>
                                <tr class="rBorder" [ngClass]="changeOpacity ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">Free dCoins
                                        
                                    </td>

                                    <td class="font12">100</td>
                                    <td class="font12">150</td>
                                    <td class="font12">200</td>
                                    <td class="font12">250</td>
                                </tr>
                                <tr class="rBorder" [ngClass]="changeOpacity ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">Plugin Viewers</span>
                                            <p class="tooltiptext table-tooltip">Tally Viewers can access documents
                                                through Tally but will not be able to log in to your Workspace.</p>
                                        </div>
                                    </td>

                                    <td class="font12">1 per registered user</td>
                                    <td class="font12">1 per registered user</td>
                                    <td class="font12">1 per registered user</td>
                                    <td class="font12">1 per registered user</td>
                                </tr>
                                <tr class="rBorder" [ngClass]="changeOpacity ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">

                                        <div class="tool-tip ">
                                            <span class="border-dotted">Custom dox2U subdomain for your team</span>
                                            <p class="tooltiptext table-tooltip">You can create a custom sub-domain
                                                as a specially designated host-name for your team, for example
                                                <br><br>
                                                <a>YourName.dox2u.com</a>
                                            </p>

                                        </div>

                                    </td>

                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>

                                <tr *ngIf="showMore" class="rBorder" [ngClass]="changeOpacity ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip ">
                                            <span class="border-dotted ">Smart Cabinet: Intelligent retrieval
                                            </span>
                                            <p class="tooltiptext table-tooltip">Organized view of all documents in
                                                your
                                                workspace for easy &amp; intuitive retrieval


                                            </p>
                                        </div>
                                    </td>

                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>

                                <tr *ngIf="showMore" class="rBorder" [ngClass]="changeOpacity ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip ">
                                            <span class="border-dotted">Tagging and Indexing</span>
                                            <p class="tooltiptext table-tooltip">Add metadata tags using our
                                                innovative
                                                templates for standardizing how you want your documents structured

                                            </p>
                                        </div>
                                    </td>

                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>

                                <tr *ngIf="showMore" class="row-white rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''" >
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip ">
                                            <span class="">Search on Tags, Metadata and Filename</span>
                                           <p class="tooltiptext table-tooltip">Add metadata tags using our
                                                innovative
                                                templates for standardizing how you want your documents structured
                                            </p> 
                                        </div>

                                    </td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>
                                <tr *ngIf="showMore" class="rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip ">
                                            <span class="border-dotted">Search on content from Non-Image
                                                Formats</span>
                                            <p class="tooltiptext table-tooltip">Use content from Non-Image Formats
                                                (like .docx files) to retrieve them in seconds</p>
                                        </div>
                                    </td>

                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>

                                <tr *ngIf="showMore" class="row-white rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''" >
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip ">
                                            <span class="border-dotted">Data Encryption (both in transit &amp; at
                                                rest)</span>
                                            <p class="tooltiptext table-tooltip">We use time-tested and highly
                                                secure AES-256 and TLS encryptions ensuring your content is secure
                                                at all times


                                            </p>
                                        </div>
                                    </td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>
                                <tr *ngIf="showMore" class="row-white rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip ">
                                            <span class="border-dotted">Plugin for Tally ERP </span>
                                            <p class="tooltiptext table-tooltip">Plugin for Tally.ERP allowing
                                                documents to be attahced to Tally Vouchers and synced with dox2U for
                                                archival. </p>
                                        </div>
                                    </td>

                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>

                                </tr>
                                <tr *ngIf="showMore" class="rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip ">
                                            <span class="border-dotted">Secure sharing with Guests</span>
                                            <p class="tooltiptext table-tooltip">Use dox2U's Guest Sharing to
                                                securely share documents with external stakeholders like vendors,
                                                customers, partners and more!</p>
                                        </div>
                                    </td>

                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>
                                <tr *ngIf="showMore" class="rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip ">
                                            <span class="border-dotted">Sharing over WhatsApp</span>
                                            <p class="tooltiptext table-tooltip">Share documents with Guests over WhatsApp
                                                with OTP & duration-controlled access.
                                            </p>
                                        </div>
                                    </td>

                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>
                                <tr *ngIf="showMore" class="row-white rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">

<td class="feature-content cursor-pointer">
<div class="tool-tip">
    <span class="border-dotted">Extensive Audit Logs</span>
    <p class="tooltiptext table-tooltip">All user actions in your workspace
        are pooled in a central Audit Log, creating a record that helps you
        secure your Workspace by knowing how your team is accessing your
        information</p>
</div>
</td>

<td class="font12">No</td>
<td class="font12">Yes (7 days duration)</td>
<td class="font12">Yes (30 days duration)</td>
<td class="font12">Yes (Unlimited duration)</td>
                                </tr>

                                <tr *ngIf="showMore" class="row-white rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">Trash Management (Duration)</span>
                                            <p class="tooltiptext table-tooltip">Deleted files are archived in your
                                                Workspace Trash for a specified duration facilitating easy recovery
                                            </p>
                                        </div>
                                    </td>

                                    <td class="font12">No</td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>
                                <tr *ngIf="showMore" class="row-white rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">Workspace Analytics</span>
                                            <p class="tooltiptext table-tooltip">View Analytics about your dox2U
                                                Workspace and all the information being stored on your cloud
                                                repository!</p>
                                        </div>
                                    </td>
                                    <td class="font12">No</td>

                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>
                                <tr *ngIf="showMore" class="rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">Doc Preview</span>
                                            <p class="tooltiptext table-tooltip">Quick access to preview a
                                                document's content without needing to open it for viewing</p>
                                        </div>
                                    </td>

                                    <td class="font12">No</td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>

                                <tr *ngIf="showMore" class="rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">Detailed Doc Activity Log</span>
                                            <p class="tooltiptext table-tooltip">All acitivities carried out on each
                                                document is logged in the Document Activity Log, giving you complete
                                                information about how your documents are being accessed / used

                                            </p>
                                        </div>
                                    </td>

                                    <td class="font12">No</td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>
                                <tr *ngIf="showMore" class="rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">Search based on content from Image
                                                Files</span>
                                            <p class="tooltiptext table-tooltip">Our deep search uses content for
                                                helping you find the precise information you look for</p>
                                        </div>
                                    </td>

                                    <td class="font12">No</td>
                                    <td class="font12">No</td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>

                                <tr *ngIf="showMore" class="rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">Text Extraction (OCR / ICR) from
                                                Images</span>
                                            <p class="tooltiptext table-tooltip">dox2U's cutting-edge Text
                                                Extraction engine uses advanced AI &amp; OCR capabilities to convert
                                                information locked within images into workable, digital content

                                            </p>
                                        </div>
                                    </td>

                                    <td class="font12">No</td>
                                    <td class="font12">No</td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>
                                <tr *ngIf="showMore" class="row-white rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">Bulk Tagging</span>
                                            <p class="tooltiptext table-tooltip">
                                                Add Tags to multiple documents with ease
                                            </p>
                                        </div>
                                    </td>

                                    <td class="font12">No</td>
                                    <td class="font12">No</td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                           
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>
                                <tr *ngIf="showMore" class="rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">Bulk Sharing</span>
                                            <p class="tooltiptext table-tooltip">Share multiple documents at once!
                                            </p>
                                        </div>
                                    </td>

                                    <td class="font12">No</td>
                                    <td class="font12">No</td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>
                                <tr *ngIf="showMore" class="row-white rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">Granular Access Management</span>
                                            <p class="tooltiptext table-tooltip">Granular rights-based for
                                                downloading, uploading, printing and editing documents</p>
                                        </div>
                                    </td>

                                    <td class="font12">No</td>
                                    <td class="font12">No</td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>
                                <tr *ngIf="showMore" class="rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">Customized Roles &amp; Rights</span>
                                            <p class="tooltiptext table-tooltip">Create custom roles to manage how
                                                your team can access documents in your workspace.</p>
                                        </div>
                                    </td>

                                    <td class="font12">No</td>
                                    <td class="font12">No</td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>
                                <tr *ngIf="showMore" class="row-white rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">Access Searchable PDF for Image Files</span>
                                            <p class="tooltiptext table-tooltip">We convert images into Searchable
                                                PDFs that you can download or copy content from</p>
                                        </div>
                                    </td>

                                    <td class="font12">No</td>
                                    <td class="font12">No</td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>

                                <tr *ngIf="showMore" class="row-white rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">Dockets</span>
                                            <p class="tooltiptext table-tooltip">Dockets bring all pieces of
                                                process-related information into a customizable, categorized
                                                checklist.</p>
                                        </div>
                                    </td>


                                    <td class="font12">No</td>
                                    <td class="font12">No</td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>

                             
                                <tr *ngIf="showMore" class="rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">API  Integration </span>
                                            <p class="tooltiptext table-tooltip">Use dox2U's Integration APIs to
                                                create a connected information warehouse by bringing in
                                                documentation from all solutions in your business' ecosystem into
                                                dox2U

                                            </p>
                                        </div>
                                    </td>


                                    <td class="font12">No</td>
                                    <td class="font12">No</td>
                                    <td class="font12">No</td>
                                    <td class="font12" style="font-size: 15px;">Yes (15,000 credits/day)</td>
                                </tr>
                                <tr *ngIf="showMore" class="row-white rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">Create and Manage User Groups</span>
                                            <p class="tooltiptext table-tooltip">Organize users in Groups for easier
                                                management &amp; administration</p>
                                        </div>
                                    </td>

                                    <td class="font12">No</td>
                                    <td class="font12">No</td>
                                    <td class="font12">No</td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>
                             

                                <tr *ngIf="showMore" class="rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">Invite users using your domain</span>
                                            <p class="tooltiptext table-tooltip">Open up access to your workspace by
                                                specifying a private domain instead of sending out individual
                                                invites to members</p>
                                        </div>
                                    </td>

                                    <td class="font12">No</td>
                                    <td class="font12">No</td>
                                    <td class="font12">No</td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>
                         
                                <tr *ngIf="showMore" class="rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">IP Whitelisting</span>
                                            <p class="tooltiptext table-tooltip">Secure your workspace (and data) by
                                                restricting access from selected IPs only</p>
                                        </div>
                                    </td>

                                    <td class="font12">No</td>
                                    <td class="font12">No</td>
                                    <td class="font12">No</td>
                                          
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>

                           </tbody>
                                <tbody>
                               <tr>
                               <td colspan="7" class="text-center">
                            <span id="btn" (click)="toggleShowMore()"> {{ showMore ? 'Show Less' : 'Show More' }} <img src="../../../assets/Image/see-more.svg" alt=" see more"> </span>
                              </td>
                              </tr>
                              </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
</section> -->
<section class="compare-plans" *ngIf="isLaunchTime">
    <div class="container special containerOverwrite ">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto p-0">
            <div class="pricing-banner-content mb-4 pb-3">
                                    <h1 class="heading-1 text-center pb-1 mb-2" style="padding-top:56px">Feature Comparison
                                    </h1>
                                </div>

                <!-- new code here -->
                <div class="card card-body tablemargin">
                        <div class="pricing-table">
                            <table class="feature-table tbl" id="expandable-table">
                              <!-- #region --><thead>
                                <tr class=" compare-plans-card ">
                                <td><span class="compare-plan-heading">Features</span></td>

                                    <td><span class="compare-plan-heading">Business Starter</span></td>
                                    <td><span class="compare-plan-heading">Business</span></td>
                                    <td> <span class="compare-plan-heading">Business Plus</span></td>
                                    <td> <span class="compare-plan-heading">Teams</span></td>
                                </tr>
                                </thead>

                                <tbody>

                                <!-- pricing-table-heading-->
                                <tr>
                                    <th #subheader class="subheader" scope="rowgroup" colspan="5">General</th>
                                </tr>
                                <tr class="row-white rBorder">
                                    <td class="feature-content cursor-pointer">

                                        <div class="tool-tip">
                                            <span class="">Storage</span>
                                            <!-- <p class="tooltiptext table-tooltip">Each dox2U page (dPage) is equivalent to
                                        500 KB, which is the average size of an A4-sized page scanned @ 300dpi. We
                                        bill in this convenient unit instead of KB / MB / GB</p> -->

                                        </div>
                                    </td>

                                    <td><span class="table-heading mb-0">20 GB</span></td>
                                    <td><span class="table-heading mb-0">50 GB/user</span></td>
                                    <td><span class="table-heading mb-0">200 GB/user</span></td>
                                    <td><span class="table-heading mb-0">500 GB/user</span></td>

                                </tr>
                               
                                <tr class="row-white rBorder">
                                    <td class="feature-content cursor-pointer">

                                        <div class="tool-tip">
                                            <span class="">Maximum size per document</span>
                                            <!-- <p class="tooltiptext table-tooltip">Each dox2U page (dPage) is equivalent to
                                        500 KB, which is the average size of an A4-sized page scanned @ 300dpi. We
                                        bill in this convenient unit instead of KB / MB / GB</p> -->

                                        </div>
                                    </td>

                                    <td><span class="table-heading mb-0">30 MB</span></td>
                                    <td><span class="table-heading mb-0">150 MB</span></td>
                                    <td><span class="table-heading mb-0">500 MB</span></td>
                                    <td><span class="table-heading mb-0">1 GB</span></td>

                                </tr>
                                <!--pricing-table-content-->
                                <tr class="rBorder" [ngClass]="changeOpacity ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <span>Minimum Users</span>
                                    </td>

                                    <td> <span class="table-heading">1</span></td>
                                    <td><span class="table-heading">1</span></td>
                                    <td> <span class="table-heading">1</span></td>
                                    <td><span class="table-heading mb-0">1</span></td>
                                </tr>

                                <tr class="row-white rBorder" [ngClass]="changeOpacity ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">Maximum Users</td>

                                    <td><span class="table-heading">1</span></td>
                                    <td> <span class="table-heading">Unlimited</span></td>
                                    <td><span class="table-heading">Unlimited</span></td>
                                    <td><span class="table-heading mb-0">Unlimited</span></td>
                                </tr>
                                <tr class="rBorder" [ngClass]="changeOpacity ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">Free dCoins
                                        
                                    </td>

                                    <td class="font12">100</td>
                                    <td class="font12">150</td>
                                    <td class="font12">200</td>
                                    <td class="font12">250</td>
                                </tr>
                                <tr *ngIf="showMore">
                                    <th #subheader class="subheader" scope="rowgroup" colspan="5">dox2U Tally Plugin</th>
                                </tr>
                                <tr *ngIf="showMore" class="rBorder" [ngClass]="changeOpacity ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">Plugin for Tally</span>
                                            <p class="tooltiptext table-tooltip">Plugin for Tally allowing documents to be attahced to Tally Vouchers and synced with dox2U for archival.</p>
                                        </div>
                                    </td>

                                    <td class="font12"><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td class="font12"><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td class="font12"><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td class="font12"><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>
                                <tr *ngIf="showMore" class="rBorder" [ngClass]="changeOpacity ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">Plugin Viewers</span>
                                            <p class="tooltiptext table-tooltip">Tally Viewers can access documents
                                                through Tally but will not be able to log in to your Workspace.</p>
                                        </div>
                                    </td>

                                    <td class="font12">1 per registered user</td>
                                    <td class="font12">1 per registered user</td>
                                    <td class="font12">1 per registered user</td>
                                    <td class="font12">1 per registered user</td>
                                </tr>
                                <tr *ngIf="showMore" class="rBorder" [ngClass]="changeOpacity ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">Tally Companies per workspace</span>
                                            <p class="tooltiptext table-tooltip">Maximum Tally companies connectable to dox2U Plugin for backups and document management.</p>
                                        </div>
                                    </td>

                                    <td class="font12">2</td>
                                    <td class="font12">5</td>
                                    <td class="font12">10</td>
                                    <td class="font12">20</td>
                                </tr>
                                <tr>
                                    <tr *ngIf="showMore" class="rBorder" [ngClass]="changeOpacity ? 'opacity-1' : ''">
                                        <td class="feature-content cursor-pointer">
                                            <div class="tool-tip">
                                                <span class="border-dotted">Add multiple files to Vouchers / Masters</span>
                                                <p class="tooltiptext table-tooltip">Link multiple documents to a Tally Voucher or Master effortlessly.</p>
                                            </div>
                                        </td>
    
                                        <td class="font12"><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                        <td class="font12"><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                        <td class="font12"><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                        <td class="font12"><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    </tr>
                                    <tr *ngIf="showMore" class="rBorder" [ngClass]="changeOpacity ? 'opacity-1' : ''">
                                        <td class="feature-content cursor-pointer">
                                            <div class="tool-tip">
                                                <span class="border-dotted">Attach files to Vouchers / Masters from dox2U workspace</span>
                                                <p class="tooltiptext table-tooltip">Link files to Tally Vouchers/Masters directly from your dox2U workspace</p>
                                            </div>
                                        </td>
    
                                        <td class="font12">No</td>
                                        <td class="font12"><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                        <td class="font12"><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                        <td class="font12"><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    </tr>
                                    <tr *ngIf="showMore" class="rBorder" [ngClass]="changeOpacity ? 'opacity-1' : ''">
                                        <td class="feature-content cursor-pointer">
                                            <div class="tool-tip">
                                                <span class="border-dotted">Auto save Vouchers as PDFs</span>
                                                <p class="tooltiptext table-tooltip">Automatically save Tally Vouchers as PDFs to your dox2U workspace</p>
                                            </div>
                                        </td>
    
                                        <td class="font12">No</td>
                                        <td class="font12"><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                        <td class="font12"><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                        <td class="font12"><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    </tr>
                                    <tr *ngIf="showMore" class="rBorder" [ngClass]="changeOpacity ? 'opacity-1' : ''">
                                        <td class="feature-content cursor-pointer">
                                            <div class="tool-tip">
                                                <span class="border-dotted">Enforce documents in Vouchers</span>
                                                <p class="tooltiptext table-tooltip">Make document attachment mandatory for selected Tally voucher types</p>
                                            </div>
                                        </td>
    
                                        <td class="font12">No</td>
                                        <td class="font12">No</td>
                                        <td class="font12"><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                        <td class="font12"><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    </tr>
                                   
                                        <tr *ngIf="showMore" class="rBorder" [ngClass]="changeOpacity ? 'opacity-1' : ''">
                                            <td class="feature-content cursor-pointer">
                                                <div class="tool-tip">
                                                    <span class="border-dotted">Automated Tally Backup</span>
                                                    <p class="tooltiptext table-tooltip">Make document attachment mandatory for selected Tally voucher types</p>
                                                </div>
                                            </td>
        
                                            <td class="font12"><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td class="font12"><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td class="font12"><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td class="font12"><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                        </tr>
                                        <tr *ngIf="showMore" class="rBorder" [ngClass]="changeOpacity ? 'opacity-1' : ''">
                                            <td class="feature-content cursor-pointer">
                                                <div class="tool-tip">
                                                    <span class="border-dotted">1. Backup Scheduling Rules</span>
                                                    <p class="tooltiptext table-tooltip">Maximum number of scheduled backup rules a workspace can configure</p>
                                                </div>
                                            </td>
        
                                            <td class="font12">2</td>
                                            <td class="font12">5</td>
                                            <td class="font12">15</td>
                                            <td class="font12">30</td>
                                        </tr>
                                        <tr *ngIf="showMore" class="rBorder" [ngClass]="changeOpacity ? 'opacity-1' : ''">
                                            <td class="feature-content cursor-pointer">
                                                <div class="tool-tip">
                                                    <span class="border-dotted">2. Backups per Tally Company</span>
                                                    <p class="tooltiptext table-tooltip">Maximum number of backups stored per Tally Company in dox2U</p>
                                                </div>
                                            </td>
        
                                            <td class="font12">7</td>
                                            <td class="font12">10</td>
                                            <td class="font12">20</td>
                                            <td class="font12">30</td>
                                        </tr>
                                        <tr *ngIf="showMore" class="rBorder" [ngClass]="changeOpacity ? 'opacity-1' : ''">
                                            <td class="feature-content cursor-pointer">
                                                <div class="tool-tip">
                                                    <span class="border-dotted">3. Backup Retention</span>
                                                    <p class="tooltiptext table-tooltip">Permanently save selected backups to prevent automatic deletion</p>
                                                </div>
                                            </td>
        
                                            <td class="font12"><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td class="font12"><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td class="font12"><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td class="font12"><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                        </tr>
                                        <tr *ngIf="showMore" class="rBorder" [ngClass]="changeOpacity ? 'opacity-1' : ''">
                                            <td class="feature-content cursor-pointer">
                                                <div class="tool-tip">
                                                    <span class="border-dotted">4. Secure Backup Scanning</span>
                                                    <p class="tooltiptext table-tooltip">Backups are scanned automatically to detect viruses and protect your data</p>
                                                </div>
                                            </td>
        
                                            <td class="font12"><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td class="font12"><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td class="font12"><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td class="font12"><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                        </tr>
                                        <tr *ngIf="showMore">
                                    <th #subheader class="subheader" scope="rowgroup" colspan="5">dox2U Cloud DMS</th>
                                </tr>
                             
                                <tr *ngIf="showMore" class="rBorder" [ngClass]="changeOpacity ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">

                                        <div class="tool-tip ">
                                            <span class="border-dotted">Custom dox2U subdomain for your team</span>
                                            <p class="tooltiptext table-tooltip">You can create a custom sub-domain
                                                as a specially designated host-name for your team, for example
                                                <br><br>
                                                <a>YourName.dox2u.com</a>
                                            </p>

                                        </div>

                                    </td>

                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>

                                <tr *ngIf="showMore" class="rBorder" [ngClass]="changeOpacity ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip ">
                                            <span class="border-dotted ">Smart Cabinet: Intelligent retrieval
                                            </span>
                                            <p class="tooltiptext table-tooltip">Organized view of all documents in
                                                your
                                                workspace for easy &amp; intuitive retrieval


                                            </p>
                                        </div>
                                    </td>

                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>

                                <tr *ngIf="showMore" class="rBorder" [ngClass]="changeOpacity ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip ">
                                            <span class="border-dotted">Tagging and Indexing</span>
                                            <p class="tooltiptext table-tooltip">Add metadata tags using our
                                                innovative
                                                templates for standardizing how you want your documents structured

                                            </p>
                                        </div>
                                    </td>

                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>

                                <tr *ngIf="showMore" class="row-white rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''" >
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip ">
                                            <span class="">Search on Tags, Metadata and Filename</span>
                                            <!-- <p class="tooltiptext table-tooltip">Add metadata tags using our
                                                innovative
                                                templates for standardizing how you want your documents structured
                                            </p> -->
                                        </div>

                                    </td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>
                                <tr *ngIf="showMore" class="rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip ">
                                            <span class="border-dotted">Search on content from Non-Image
                                                Formats</span>
                                            <p class="tooltiptext table-tooltip">Use content from Non-Image Formats
                                                (like .docx files) to retrieve them in seconds</p>
                                        </div>
                                    </td>

                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>

                                <tr *ngIf="showMore" class="row-white rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''" >
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip ">
                                            <span class="border-dotted">Data Encryption (both in transit &amp; at
                                                rest)</span>
                                            <p class="tooltiptext table-tooltip">We use time-tested and highly
                                                secure AES-256 and TLS encryptions ensuring your content is secure
                                                at all times


                                            </p>
                                        </div>
                                    </td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>
                                <!-- <tr *ngIf="showMore" class="row-white rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip ">
                                            <span class="border-dotted">Plugin for Tally ERP </span>
                                            <p class="tooltiptext table-tooltip">Plugin for Tally.ERP allowing
                                                documents to be attahced to Tally Vouchers and synced with dox2U for
                                                archival. </p>
                                        </div>
                                    </td>

                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>

                                </tr> -->
                                <tr *ngIf="showMore" class="rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip ">
                                            <span class="border-dotted">Secure sharing with Guests</span>
                                            <p class="tooltiptext table-tooltip">Use dox2U's Guest Sharing to
                                                securely share documents with external stakeholders like vendors,
                                                customers, partners and more!</p>
                                        </div>
                                    </td>

                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>
                                <tr *ngIf="showMore" class="rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip ">
                                            <span class="border-dotted">Sharing over WhatsApp</span>
                                            <p class="tooltiptext table-tooltip">Share documents with Guests over WhatsApp
                                                with OTP & duration-controlled access.
                                            </p>
                                        </div>
                                    </td>

                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>
                                <tr *ngIf="showMore" class="row-white rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">

<td class="feature-content cursor-pointer">
<div class="tool-tip">
    <span class="border-dotted">Extensive Audit Logs</span>
    <p class="tooltiptext table-tooltip">All user actions in your workspace
        are pooled in a central Audit Log, creating a record that helps you
        secure your Workspace by knowing how your team is accessing your
        information</p>
</div>
</td>

<td class="font12">No</td>
<td class="font12">Yes (7 days duration)</td>
<td class="font12">Yes (30 days duration)</td>
<td class="font12">Yes (Unlimited duration)</td>
                                </tr>

                                <tr *ngIf="showMore" class="row-white rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">Trash Management (Duration)</span>
                                            <p class="tooltiptext table-tooltip">Deleted files are archived in your
                                                Workspace Trash for a specified duration facilitating easy recovery
                                            </p>
                                        </div>
                                    </td>

                                    <td class="font12">No</td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>
                                <tr *ngIf="showMore" class="row-white rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">Workspace Analytics</span>
                                            <p class="tooltiptext table-tooltip">View Analytics about your dox2U
                                                Workspace and all the information being stored on your cloud
                                                repository!</p>
                                        </div>
                                    </td>
                                    <td class="font12">No</td>

                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>
                                <tr *ngIf="showMore" class="rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">Doc Preview</span>
                                            <p class="tooltiptext table-tooltip">Quick access to preview a
                                                document's content without needing to open it for viewing</p>
                                        </div>
                                    </td>

                                    <td class="font12">No</td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>

                                <tr *ngIf="showMore" class="rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">Detailed Doc Activity Log</span>
                                            <p class="tooltiptext table-tooltip">All acitivities carried out on each
                                                document is logged in the Document Activity Log, giving you complete
                                                information about how your documents are being accessed / used

                                            </p>
                                        </div>
                                    </td>

                                    <td class="font12">No</td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>
                                <tr *ngIf="showMore" class="rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">Search based on content from Image
                                                Files</span>
                                            <p class="tooltiptext table-tooltip">Our deep search uses content for
                                                helping you find the precise information you look for</p>
                                        </div>
                                    </td>

                                    <td class="font12">No</td>
                                    <td class="font12">No</td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>

                                <tr *ngIf="showMore" class="rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">Text Extraction (OCR / ICR) from
                                                Images</span>
                                            <p class="tooltiptext table-tooltip">dox2U's cutting-edge Text
                                                Extraction engine uses advanced AI &amp; OCR capabilities to convert
                                                information locked within images into workable, digital content

                                            </p>
                                        </div>
                                    </td>

                                    <td class="font12">No</td>
                                    <td class="font12">No</td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>
                                <tr *ngIf="showMore" class="row-white rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">Bulk Tagging</span>
                                            <p class="tooltiptext table-tooltip">
                                                Add Tags to multiple documents with ease
                                            </p>
                                        </div>
                                    </td>

                                    <td class="font12">No</td>
                                    <td class="font12">No</td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                           
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>
                                <tr *ngIf="showMore" class="rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">Bulk Sharing</span>
                                            <p class="tooltiptext table-tooltip">Share multiple documents at once!
                                            </p>
                                        </div>
                                    </td>

                                    <td class="font12">No</td>
                                    <td class="font12">No</td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>
                                <tr *ngIf="showMore" class="row-white rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">Granular Access Management</span>
                                            <p class="tooltiptext table-tooltip">Granular rights-based for
                                                downloading, uploading, printing and editing documents</p>
                                        </div>
                                    </td>

                                    <td class="font12">No</td>
                                    <td class="font12">No</td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>
                                <tr *ngIf="showMore" class="rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">Customized Roles &amp; Rights</span>
                                            <p class="tooltiptext table-tooltip">Create custom roles to manage how
                                                your team can access documents in your workspace.</p>
                                        </div>
                                    </td>

                                    <td class="font12">No</td>
                                    <td class="font12">No</td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>
                                <tr *ngIf="showMore" class="row-white rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">Access Searchable PDF for Image Files</span>
                                            <p class="tooltiptext table-tooltip">We convert images into Searchable
                                                PDFs that you can download or copy content from</p>
                                        </div>
                                    </td>

                                    <td class="font12">No</td>
                                    <td class="font12">No</td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>

                                <tr *ngIf="showMore" class="row-white rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">Dockets</span>
                                            <p class="tooltiptext table-tooltip">Dockets bring all pieces of
                                                process-related information into a customizable, categorized
                                                checklist.</p>
                                        </div>
                                    </td>


                                    <td class="font12">No</td>
                                    <td class="font12">No</td>
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>

                             
                                <tr *ngIf="showMore" class="rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">API  Integration </span>
                                            <p class="tooltiptext table-tooltip">Use dox2U's Integration APIs to
                                                create a connected information warehouse by bringing in
                                                documentation from all solutions in your business' ecosystem into
                                                dox2U

                                            </p>
                                        </div>
                                    </td>


                                    <td class="font12">No</td>
                                    <td class="font12">No</td>
                                    <td class="font12">No</td>
                                    <td class="font12" style="font-size: 15px;">Yes (15,000 credits/day)</td>
                                </tr>
                                <tr *ngIf="showMore" class="row-white rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">Create and Manage User Groups</span>
                                            <p class="tooltiptext table-tooltip">Organize users in Groups for easier
                                                management &amp; administration</p>
                                        </div>
                                    </td>

                                    <td class="font12">No</td>
                                    <td class="font12">No</td>
                                    <td class="font12">No</td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>
                             

                                <tr *ngIf="showMore" class="rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">Invite users using your domain</span>
                                            <p class="tooltiptext table-tooltip">Open up access to your workspace by
                                                specifying a private domain instead of sending out individual
                                                invites to members</p>
                                        </div>
                                    </td>

                                    <td class="font12">No</td>
                                    <td class="font12">No</td>
                                    <td class="font12">No</td>
                                            <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>
                         
                                <tr *ngIf="showMore" class="rBorder" [ngClass]="showAnnualData ? 'opacity-1' : ''">
                                    <td class="feature-content cursor-pointer">
                                        <div class="tool-tip">
                                            <span class="border-dotted">IP Whitelisting</span>
                                            <p class="tooltiptext table-tooltip">Secure your workspace (and data) by
                                                restricting access from selected IPs only</p>
                                        </div>
                                    </td>

                                    <td class="font12">No</td>
                                    <td class="font12">No</td>
                                    <td class="font12">No</td>
                                          
                                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                                </tr>

                           </tbody>
                                <tbody>
                               <tr>
                               <td colspan="7" class="text-center">
                            <span id="btn" (click)="toggleShowMore()"> {{ showMore ? 'Show Less' : 'Show More' }} <img src="../../../assets/Image/see-more.svg" alt=" see more"> </span>
                              </td>
                              </tr>
                              </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
</section>

<section class="tally-blue">
    <div class="container containerOverwrite ">
        <div class="row">
            <div class="d-flex justify-content-center flex-column align-items-center  col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12  ">

                <div class="  col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12   ">
                    <div class="row tally-blue-bg align-items-center">
                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mx-auto ">
                            <div class="d-flex flex-column center-align-div">
                                <p class="whiteText tally-h6 mb-1">dox2U’s Tally DMS Plugin</p>
                                <h2 class="heading-3 exciting-margin-bottom center-align-div whiteText">Organize and
                                    manage your accounts &amp; finance
                                    documents <span class="underline-highlight-ex">from Tally</span> </h2>
                                <ul class="m-0 p-0">
                                    <li class="d-flex margin-10 justify-content-start align-items-start "><img src="../../../assets/Image/checkwhite.svg" alt="" class="img-fluid mr-1 img-fluid-mr">
                                        <p class="common-para whiteText mb-0 access-align ">Documents get stored in
                                            your
                                            dox2U workspace
                                        </p>
                                    </li>

                                    <li class="d-flex margin-10 justify-content-start align-items-start "><img src="../../../assets/Image/checkwhite.svg" alt="" class="img-fluid mr-1 img-fluid-mr">
                                        <p class="common-para whiteText mb-0 access-align ">Access attached
                                            documents from
                                            Tally
                                        </p>
                                    </li>
                                    <li class="d-flex margin-10 justify-content-start align-items-start  "><img src="../../../assets/Image/checkwhite.svg" alt="" class="img-fluid mr-1 img-fluid-mr">
                                        <p class="common-para whiteText mb-0 access-align ">Share documents with
                                            anyone,
                                            anywhere
                                        </p>
                                    </li>

                                </ul>
                                <p class="mt-3 pt-1 common-para"><a [routerLink]="['/tally']" style="color:white">Learn more <img src="../../../assets/Image/learnmore.svg"></a></p>
                            </div>
                        </div>
                        <div class=" col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mx-auto p-0 ">
                            <div class="d-flex flex-column center-align-div">
                                <div class="d-flex align-items-center justify-content-center tallyLogoM">
                                    <img src="../../../assets/Image/tallylogo.svg" class="img-fluid dplay576">
                                    <img src="../../../assets/Image/tallylogo2.svg" class="img-fluid dplay5761">
                                </div>

                                <div class="d-flex align-items-flex-end justify-content-end"> <img src="../../../assets/Image/dox2u_tally1.svg" class="img-fluid dplay">
                                    <img src="../../../assets/Image/dox2u_tally2.svg" class="img-fluid dplay1">
                                </div>

                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    </div>

</section>

<section class="feature-comparision pt-0 common-padd  bottmp">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10 mx-auto">
                <div class="row">
                    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div class="feature-comparision-top d-flex flex-column align-items-center mb-5 pb-3">
                            <h2 class="mb-3"><b>Frequently asked questions</b></h2>
                            <p class="askPara">We are here to explain everything you might want to know. Let us help
                            </p>
                        </div>
                        <div class="feature-comparision-Acordian">
                            <div id="accordion" class="accordion">
                            <div class="acc-section">
                                <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseOne" >
                                    <h4 class="accordian-headings mb-0" style="color: rgb(88, 88, 88);">How much data can 50 GB hold? </h4>
                                    <img src="../../../assets/Image/plus.svg" class="plus-img">
                                    <img src="../../../assets/Image/minus.svg" class="minus-img">
                                </div>
                                <div id="collapseOne" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                    <p>The average user stores a mix of different formats and types of documented
                                        information. 50 GB gives you the option of storing roughly:
                                    </p>
                                    <li>100,000 pages of A4 sized scanned documents (appx 500 KB per page)</li>
                                    <li>
                                        More than 200,000 workable document files (docx, doc, txt, etc..)
                                    </li>
                                </div>
                            </div>

                            <div class="acc-section">
                                <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseTwo">
                                    <h4 class="accordian-headings mb-0">What if I need additional storage?</h4>
                                    <img src="../../../assets/Image/plus.svg" class="plus-img">
                                    <img src="../../../assets/Image/minus.svg" class="minus-img">
                                </div>
                                <div id="collapseTwo" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                    <p>If you are on our limited storage plans, you can either free up storage by
                                        deleting the documents that you do not use OR add more users to your
                                        subscription OR you can choose to upgrade your plan (with unlimited storage
                                        plans available) as per your need.
                                    </p>

                                </div>
                            </div>

                            <div class="acc-section">
                                <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseSeven">
                                    <h4 class="accordian-headings mb-0">What are dCoins?</h4>
                                    <img src="../../../assets/Image/plus.svg" class="plus-img">
                                    <img src="../../../assets/Image/minus.svg" class="minus-img">
                                </div>
                                <div id="collapseSeven" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                    <p>dCoins are used by us to facilitate you for using some of our pay-per-use services. 
                                        Such pay-per-use services are prepaid and you will need dCoins in your Wallet with us to use these services. 
                                        You can choose the right-fit pack depending on your usage. Currently we offer WhatsApp messaging as part of 
                                        such services which will soon be expanded to include other cognitive features.
                                    </p>

                                </div>
                            </div>

                            <div class="acc-section">
                                <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseThree">
                                    <h4 class="accordian-headings mb-0">What are the accepted payment methods?</h4>
                                    <img src="../../../assets/Image/plus.svg" class="plus-img">
                                    <img src="../../../assets/Image/minus.svg" class="minus-img">
                                </div>
                                <div id="collapseThree" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                    <p>Payments to dox2U can be made using most major Debit and Credit Cards like
                                        Visa, MasterCard or AMEX. We will soon be adding other options for accepting
                                        digital payments online. Meanwhile, if you prefer using other modes of
                                        digital payments, you could choose the Offline Payment method while managing
                                        your subscriptions.</p>
                                </div>
                            </div>

                            <div class="acc-section">
                                <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseFour">
                                    <h4 class="accordian-headings mb-0">Are there any discounts available for
                                        start-ups, educational institutions, and NGOs?</h4>
                                        <img src="../../../assets/Image/plus.svg" class="plus-img">
                                        <img src="../../../assets/Image/minus.svg" class="minus-img">
                                </div>
                                <div id="collapseFour" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                    <p>At dox2U, we support start-ups and organizations that are striving to bring
                                        about positive change. Please contact dox2U’s sales team for further
                                        information on the availability of discounts.
                                    </p>

                                </div>
                            </div>

                            <div class="acc-section">
                                <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseFive">
                                    <h4 class="accordian-headings mb-0">How do you provide training and support on
                                        using dox2U?
                                    </h4>
                                    <img src="../../../assets/Image/plus.svg" class="plus-img">
                                    <img src="../../../assets/Image/minus.svg" class="minus-img">
                                </div>
                                <div id="collapseFive" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                    <p>dox2U is an amazingly intuitive system to work with. A close associate says
                                        “If you know what you want to do, then you don’t need to think how to do it
                                        in dox2U.”

                                    </p>
                                    <br>

                                    <p>Further on, there is a ready online support that works round the clock and
                                        assists the users in the midst of any task. Clicking on the ‘Live Help’
                                        brings up trained associate already on the same page knowing where you are,
                                        and saving you the time and trouble to explain. The expert staff assists you
                                        in real-time and takes you through the task just short of actually doing it
                                        for you.
                                    </p>
                                    <br>
                                    <p>Meanwhile more videos are getting added to help you manage configurations and
                                        for other operational controls</p>
                                </div>
                            </div>

                            <div class="acc-section">
                                <div id="collapseSix1" class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseSix">
                                    <h4 class="accordian-headings mb-0"> How does dox2U's billing work?</h4>
                                    <img src="../../../assets/Image/plus.svg" class="plus-img">
                                    <img src="../../../assets/Image/minus.svg" class="minus-img">
                                </div>
                                <div id="collapseSix" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                    <p>dox2U's pricing consists of two components - plan charges and add-on users.
                                       <br><br>
                                        Each Plan includes 1 user by default. In case your subscription plan supports the addition <br> of 
                                        multiple users, each additional user is charge as per the Add-On User price defined <br> for this plan.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-getting-started #GettingStartedComponent></app-getting-started>
<div class="gradient-divider"></div>
</section>
<app-countdown-timer class="d-none" (countdownEnded)="onCountdownEnd($event)"></app-countdown-timer>

    