import { Component, Input } from '@angular/core';
import { productSubmenu } from 'src/app/interfaces/interfaces';
import { productHanle } from 'src/constants/product-content';

@Component({
  selector: 'app-product-left-text-with-button',
  templateUrl: './product-left-text-with-button.component.html',
  styleUrls: ['./product-left-text-with-button.component.scss']
})
export class ProductLeftTextWithButtonComponent {

  @Input() imgSrc:string=" ";
  @Input() contentH!:productSubmenu;

  scrollToGettingStarted(event: Event) {
    const gettingStartedElement = document.getElementById('gettingStarted');
    let locationUrl = window.location.href;
    if (gettingStartedElement) {
      const viewportHeight = window.innerHeight;
            const elementHeight = gettingStartedElement.clientHeight;
            const yOffset = (viewportHeight - elementHeight) / 2;
          const scrollOptions: ScrollToOptions = {
            top: gettingStartedElement.offsetTop - yOffset,
            behavior: 'smooth',
          };
          window.scrollTo(scrollOptions);
      //gettingStartedElement.scrollIntoView({ behavior: 'smooth' });
    }

    else if(locationUrl.endsWith('tallybackup')){
      
    const waitingFormElem = document.getElementById('formtally');
    console.log("tallyBackup", waitingFormElem);
    if(waitingFormElem) {
      const viewportHeight = window.innerHeight;
      const elementHeight = waitingFormElem.clientHeight;
      const yOffset = (viewportHeight - elementHeight) / 2;
    const scrollOptions: ScrollToOptions = {
      top: waitingFormElem.offsetTop - yOffset,
      behavior: 'smooth',
    };
    window.scrollTo(scrollOptions);
    }
    }

  //   else if(locationUrl.endsWith('tally')) {
  //     const tallyBackupSection = document.getElementById('tallyBackupSection');
  //   console.log("tallyBackupSection", tallyBackupSection);
  //   if(tallyBackupSection) {
  //     const viewportHeight = window.innerHeight;
  //     const elementHeight = tallyBackupSection.clientHeight;
  //     const yOffset = (viewportHeight - elementHeight) / 2;
  //   const scrollOptions: ScrollToOptions = {
  //     top: tallyBackupSection.offsetTop - yOffset,
  //     behavior: 'smooth',
  //   };
  //   window.scrollTo(scrollOptions);
  //   }

  // }

  else if(locationUrl.endsWith('tally')) {
    const downloadForm = document.getElementById('downloadForm');
    console.log("downloadForm", downloadForm);
    if(downloadForm) {
      const viewportHeight = window.innerHeight;
      const elementHeight = downloadForm.clientHeight;
      const yOffset = (viewportHeight - elementHeight) / 2;
    const scrollOptions: ScrollToOptions = {
      top: downloadForm.offsetTop - yOffset,
      behavior: 'smooth',
    };
    window.scrollTo(scrollOptions);
    }
  }
}

openCalendlyPage() {
  let locationUrl = window.location.href;
  

  if(locationUrl.endsWith('tallydms')) {
    window.open('https://calendly.com/dox2u-dms/dox2u-tally-plugin-demo-ws', '_blank');
  }

  else if(locationUrl.endsWith('tallybackup')) {
    window.open('https://calendly.com/dox2u-dms/tally-backup', '_blank');
  }

  else if(locationUrl.endsWith('tally')) {
    window.open(' https://calendly.com/dox2u-dms/tally-plugin-common', '_blank');
  }
}
}
