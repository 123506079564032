import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes, Scroll } from '@angular/router';
import { Dox2UForTallyComponent } from './components/dox2-u-for-tally/dox2-u-for-tally.component';
import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FeatureComponent } from './components/product/feature/feature.component';
import { DocumentHandlingComponent } from './components/product/document-handling/document-handling.component';
import { SecurityAndReliabilityComponent } from './components/product/security-and-reliability/security-and-reliability.component';
import { SmartCabinetComponent } from './components/product/smart-cabinet/smart-cabinet.component';
import { TextExtractionComponent } from './components/product/text-extraction/text-extraction.component';
import { GuestAccessComponent } from './components/product/guest-access/guest-access.component';
import { DeepSearchComponent } from './components/product/deep-search/deep-search.component';
import { DocketsComponent } from './components/product/dockets/dockets.component';
import { ProblemsWeAreSolvingComponent } from './components/resources/problems-we-are-solving/problems-we-are-solving.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { WhyChooseDox2UComponent } from './components/resources/why-choose-dox2-u/why-choose-dox2-u.component';
import { BuiltForEveryoneComponent } from './components/resources/built-for-everyone/built-for-everyone.component';
import { ContactusComponent } from './components/contactus/contactus.component';
import { PartnersComponent } from './components/partners/partners.component';
import { FindWorkspaceComponent } from './components/find-workspace/find-workspace.component';
import { GettingStartedComponent } from './shared/getting-started/getting-started.component';
import { DownloadDMSAddOnComponent } from './components/download-dms-add-on/download-dms-add-on.component';
import { Error404Component } from './shared/error404/error404.component';
import { DocumentManagementSystemComponent } from 'src/app/components/document-management-system/document-management-system.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TallyBackupComponent } from './components/tally-backup/tally-backup.component';
import { CountdownTimerComponent } from './shared/countdown-timer/countdown-timer.component';
import { TallyDMSPluginComponent } from './components/tally-dmsplugin/tally-dmsplugin.component';




const routes: Routes = [
  {path: '', component: DashboardComponent},
  {path: 'tallybackup', component: TallyBackupComponent},
  {path: 'tally', component: TallyDMSPluginComponent},
  {path: 'tallydms', component: Dox2UForTallyComponent},
  // {path: 'tallydmsplugin', component: TallyDMSPluginComponent},
  {path: 'feature', component:FeatureComponent},
  {path: 'document-handling', component:DocumentHandlingComponent},
  {path: 'document-handling', component:SecurityAndReliabilityComponent},
  {path: 'security', component:SecurityAndReliabilityComponent},
  {path: 'smart-cabinet', component:SmartCabinetComponent},
  {path: 'text-extraction', component:TextExtractionComponent},
  {path: 'guest-access', component:GuestAccessComponent},
  {path: 'deep-search', component:DeepSearchComponent},
  {path: 'dockets', component:DocketsComponent},
  {path: 'problems-we-are-solving', component:ProblemsWeAreSolvingComponent},
  {path: 'pricing', component: PricingComponent},
  {path: 'find-workspace', component: FindWorkspaceComponent},
  {path: 'why-choose-dox2u', component:WhyChooseDox2UComponent},
  {path: 'built-for-everyone', component:BuiltForEveryoneComponent},
  {path: 'contact-us', component:ContactusComponent},
  {path: 'partners', component:PartnersComponent},
  {path: 'download-dms-add-on', component:DownloadDMSAddOnComponent},
  {path:  'legal', component: PrivacyPolicyComponent},
  {path:  'legal/privacy-policy', component: PrivacyPolicyComponent},
  {path:  'legal/terms-and-conditions', component: PrivacyPolicyComponent},
  {path:  'legal/disclaimer', component: PrivacyPolicyComponent},
  {path:  'legal/customers-terms', component: PrivacyPolicyComponent},
  {path:  'legal/users-terms', component: PrivacyPolicyComponent},
  {path:  'legal/eula', component: PrivacyPolicyComponent}, 
  {path:  'legal/acceptable-use-policy', component: PrivacyPolicyComponent}, 
  {path:  'legal/cookie-policy', component: PrivacyPolicyComponent},
  {path:  'legal/disclaimer', component: PrivacyPolicyComponent},
  {path: 'document-management-system', component: DocumentManagementSystemComponent},
  

  {
    path: 'pricing/storage-&-retrieval-plans',
    redirectTo: 'pricing',
    pathMatch: 'full', 
  },

  {path: 'error404', component: Error404Component},
  {path:'not-found', component: Error404Component},
  {path:'**', redirectTo: 'not-found'},
 
  

  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    enableTracing: false,
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule{ }
